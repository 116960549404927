import React from "react";
import FooterOne from "../components/footers/FooterOne";
import MainHeroSection from "../features/common/MainHeroSection";
import DocMainSection from "../features/documentation/DocMainSection";

const Documentation = () => {

  return (
    <>
        <MainHeroSection title="Documentation" subTitle="We have made well documentions for easy to use"/>
        <DocMainSection/>
        <FooterOne/>
    </>

  );
};

export default Documentation;
