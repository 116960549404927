import React, { useEffect, useState } from "react";
import {
  pricingplan,
  planTable,
} from "../../assets/fake-data/landingpage-two/pricingplan";
import { Link } from "react-router-dom";

const RightPlan = () => {
  const [dataBlock] = useState({
    title: "Choose The Right Plan For Your Landik",
    subTitle:
      "Due to its widespread use as filler text for layouts, non-readability is of great importance.",
  });

  useEffect(() => {
    changeTab(parseInt(0))
  }, [])

  function changeTab(index) {
    var tabButtons = document.getElementById('tab-buttons').children;
    var tabPanels = document.getElementById('tab-panels').children;

    for (var i = 0; i < tabButtons.length; i++) {
        tabButtons[i].classList.remove('active-price-tab');
        tabPanels[i].classList.add('hidden');
    }
    tabButtons[index].classList.add('active-price-tab');
    tabPanels[index].classList.remove('hidden');
}

  return (
    <>
    <section className="py-20 bg-white relative">
        <div className="container">
            <div className="grid grid-cols-1">
                <div className="text-center">
                    <h2 className="font-bold text-[32px] md:text-[40px] capitalize mb-2 relative z-10"><span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>{dataBlock.title}</h2>
                    <p className="text-muted mx-auto text-lg max-w-[600px] mt-6">{dataBlock.subTitle}</p>
                </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 gap-[30px] mt-16 items-center">
                <div id="tab-buttons" className="space-y-[22px]">
                {pricingplan.map((x) => {
                    return  <Link key={x.id}  onClick={(e) => changeTab(parseInt(x.id))} className="sm:flex space-y-[14px] block py-5 px-4 bg-white text-dark border-2 rounded-lg border-[#e7e7e7]/50 justify-between items-center">
                       <div className="text-center sm:text-left">
                            <h5 className="font-bold text-xl">{x.plan}</h5>
                            <p className="bg-custom/20 text-custom py-1.5 px-3 rounded-full mt-2 inline-block text-[13px] active-price-tab-budges">{x.savePercent} Save</p>
                        </div>
                        <h2 className="text-[42px] text-center sm:text-right font-bold">{x.price}<sub className="font-normal text-xs relative -bottom-1">{x.subText}</sub></h2>
                    </Link>
                })}
                </div>
                <div id="tab-panels">
                {planTable.map((x) => {
                    return <div key={x.id} className="md:max-w-[400px] mx-auto border border-[#e7e7e7]/60 relative overflow-hidden py-[60px] px-[46px] rounded-lg">
                        <div className="bg-custom/[0.07] w-[200px] h-[400px] absolute -top-[2px] inline-block -right-[130px] rounded-[20px] -rotate-[34deg]"></div>
                        <div className="space-y-[26px]">
                            <p className="mt-0 font-bold"><i className="mdi mdi-check-circle text-custom mr-1"></i> {x.text1}</p>
                            <p className="mt-0 font-bold"><i className="mdi mdi-check-circle text-custom mr-1"></i> {x.text2}</p>
                            <p className="mt-0 font-bold"><i className="mdi mdi-check-circle text-custom mr-1"></i> {x.text3}</p>
                            <p className="mt-0 font-bold"><i className="mdi mdi-check-circle text-custom mr-1"></i> {x.text4}</p>
                        </div>
                        <div className="mt-12 text-right">
                            <Link to="#" className="btn-custom rounded-full">Get Started</Link>
                        </div>
                    </div>
                })}
                    
                 
                </div>
            </div>
        </div>
    </section>
    </>
  );
};

export default RightPlan;
