import React from "react";
import FooterTwo from "../components/footers/FooterTwo";
import Coustomer from "../features/common/Coustomer";
import SwiperLandingPageOne from "../features/common/SwiperLandingPageOne";
import MainHeroSection from "../features/common/MainHeroSection";

const Testimonials = () => {
  return (
    <>
      <MainHeroSection title="Testimonials" subTitle="See what our clients are saying about our services." />
      <Coustomer/>
      <SwiperLandingPageOne/>
      <FooterTwo/>
    </>
  );
};

export default Testimonials;
