import React, { useEffect, useState } from 'react'
import FooterTwo from "../components/footers/FooterTwo";
import AboutSection from "../features/about/AboutSection";
import FunFact from "../features/about/FunFact";
import TeamSection from "../features/about/TeamSection";
import ClientLogo from "../features/common/ClientLogo";
import GetStarted from "../features/common/GetStarted";
import MainHeroSection from "../features/common/MainHeroSection";
import { createClient } from "contentful";


const About = () => {
  const [blogPosts, setBlogPosts] = useState([])
  const [blogTeams, setBlogTeams] = useState([])
  const [blogLogo, setBlogLogo] = useState([])

  const client = createClient({
  //  space: process.env.REACT_APP_SPACE_ID,
    space: "vcapyi6fg0e5",
    //accessToken: process.env.REACT_APP_ACCESS_TOKEN,
   accessToken: "xpa9NUxBNUIsWaiJNltFGuirPfhhXvXIAJJpNZcgnUg",
    host: "preview.contentful.com"
  });



  useEffect(() => {

    const getAbout = async () => {
      try {
        const entries = await client.getEntries({
          content_type: "aboutUs",
          select: "fields"
        });
    
        const sanitizedEntries = entries.items.map((item) => {
         // const avatar = item.fields.avatar?.fields;
          return {
            ...item.fields
          };
        });
        setBlogPosts(sanitizedEntries)  ;
      } catch (error) {
        console.log(`Error fetching home ${error}`);
      }
    };

    const getTeams = async () => {
      try {
        const entries = await client.getEntries({
          content_type: "teams",
          select: "fields"
        });
    
        const sanitizedEntries = entries.items.map((item) => {
         // const avatar = item.fields.avatar?.fields;
          return {
            ...item.fields
          };
        });
        setBlogTeams(sanitizedEntries)  ;
      } catch (error) {
        console.log(`Error fetching home ${error}`);
      }
    };
    
    const getClientLogo = async () => {
      try {
        const entries = await client.getEntries({
          content_type: "partners",
          select: "fields"
        });
    
        const sanitizedEntries = entries.items.map((item) => {
         // const avatar = item.fields.avatar?.fields;
          return {
            ...item.fields
          };
        });
        setBlogLogo(sanitizedEntries)  ;
      } catch (error) {
        console.log(`Error fetching home ${error}`);
      }
    };
    
    
    getAbout()
    getTeams()
    getClientLogo()
 
   },[]);

   const content2 = blogPosts.find((element) => {
    return element.section==='Section 1';
  })

   const content3 = blogPosts.find((element) => {
    return element.section==='Section 2';
  })
  
   const teams = blogTeams.find((element) => {
    return element.section==='Section 1';
  })
  
  return (
    <>
      <MainHeroSection
        title={content2?.title}
        subTitle={content2?.desc}
        subTitle2={content2?.title2}
        subTitle3={content2?.title3}
      />
      <AboutSection 
        image={content2?.imageLarge.fields.file.url} 
       
        title={content3?.title}
        subTitle={content3?.desc}
        subTitle2={content3?.title2}
        titleSmall2={content3?.titleSmall2}
        titleSmall={content3?.titleSmall} />
      <GetStarted        />
      {/* <FunFact /> */}
      <TeamSection title={teams?.title} desc={teams?.desc}  image2={content2?.imageLarge2.fields.file.url}  />
      <ClientLogo blogLogo={blogLogo}/>
      <FooterTwo />
    </>
  );
};

export default About;
