import React from 'react'
import { Link } from 'react-router-dom'

const MainHeroSection = (props) => {
  return (
    <>
      <section className="md:pt-[170px] pt-[120px] pb-15 md:pb-[90px] bg-cover bg-[url(../images/bg-pages.jpg)] relative">
        <div className="container">
          <div className="grid grid-cols-1 ">
            <h1 className="font-bold text-[36px]">{props.title}</h1>
            <p className=" text-muted mt-2">{props.subTitle}</p>
            <p className="text-muted mt-4">{props.subTitle2}</p>
            <p className="text-muted  mt-2">{props.subTitle3}</p>
            {/* <div className="mt-10 ">
              <Link to="/pages/contact" className="btn-custom py-3 px-[15px]">
                Contact US
              </Link>
            </div> */}
          </div>
        </div>
      </section>
    </>
  )
}

export default MainHeroSection
