import React from "react";
import logoLight from "../../assets/images/logo-light.png";
import { Link } from "react-router-dom";

const FooterTwo = () => {
  return (
    <>
      <footer className="bg-themedark pt-20 pb-10">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-6 gap-[30px] items-start">
            <div className="order-2 lg:order-1 mt-4 md:mt-0 md:col-span-2 lg:col-auto">
              {/* <img
                src={logoLight}
                alt="logo light"
                className="img-fluid w-[120px] block text-md-center text-sm-center"
              /> */}
            </div>
            <div className="order-2 lg:order-1 md:col-span-2 lg:col-auto">
              <h6 className="text-white text-lg">Customer</h6>
              <div className="w-7 h-[2px] bg-white/20 mt-2"></div>
              <ul className="mt-6 text-white/40 space-y-2.5 text-sm">
                <li>
                  <Link
                    to="#"
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    Buyer
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    Supplier
                  </Link>
                </li>
              </ul>
            </div>
            <div className="order-2 lg:order-1 md:col-span-2 lg:col-auto">
              <h6 className="text-white text-lg">Help Center</h6>
              <div className="w-7 h-[2px] bg-white/20 mt-2"></div>
              <ul className="mt-6 text-white/40 space-y-2.5 text-sm">
                <li>
                  <Link
                    to="#"
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    Billing
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    Accounting
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="hover:text-white inline-block transition-all duration-200"
                  >
                    General Question
                  </Link>
                </li>
              </ul>
            </div>
            <div className="lg:col-span-3 md:col-span-6 order-1 lg:order-2">
              <div className="bg-custom rounded max-w-[460px] mx-auto relative py-10 px-5 -mt-[140px]">
                <div className="text-center">
                  <h2 className="font-bold text-[32px] text-white md:text-[40px] capitalize mb-2 relative z-10">
                    Get Latest Updates
                  </h2>
                  <p className="text-white mx-auto text-lg max-w-[600px] mt-6">
                    Due to its widespread use as filler text for layouts,
                    non-readability is of great importance.
                  </p>
                </div>
                <form className="mt-12 space-y-4">
                  <input
                    type="email"
                    className="form-input border border-solid h-[50px] rounded inline-block !border-white/[31%] bg-white/[15%] text-white placeholder:text-white/60"
                    placeholder="Your email.."
                    required=""
                  />
                  <button type="submit" className="btn-white rounded-[5px] w-full" disabled={true}>
                    Subcribe Now
                  </button>
                </form>
              </div>
            </div>
          </div>
          <div className="bg-white/[7%] h-px w-full mt-12 mb-8"></div>
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-[30px]">
            <div>
            {/* <p className="mt-6 text-muted">&copy; {`${new Date().getFullYear()} MRight DigitalTech Limited. Analysed and design by A. M. Ali and coded by lamydas Solutions`} </p> */}
            <p className="mt-6 text-muted">&copy; {`${new Date().getFullYear()} MRight DigitalTech Limited. Analysis and Design by A. M. Ali and coded by `}<a href='https://www.lamydas.com' target="_blank" rel="noreferrer">lamydas Solutions</a> </p>
            </div>
            <div>
              <ul className="flex items-center space-x-3 md:justify-end justify-center">
                <li>
                  <Link
                    to="#"
                    className="text-white w-[38px] h-[38px] leading-[38px] hover:text-custom duration-300 hover:-translate-y-1 rounded-full block bg-white/5 text-lg text-center"
                  >
                    <i className="mdi mdi-facebook"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="text-white w-[38px] h-[38px] leading-[38px] hover:text-custom duration-300 hover:-translate-y-1 rounded-full block bg-white/5 text-lg text-center"
                  >
                    <i className="mdi mdi-twitter"></i>
                  </Link>
                </li>
                <li>
                  <Link
                    to="#"
                    className="text-white w-[38px] h-[38px] leading-[38px] hover:text-custom duration-300 hover:-translate-y-1 rounded-full block bg-white/5 text-lg text-center"
                  >
                    <i className="mdi mdi-reddit"></i>
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default FooterTwo;
