import React, { useState } from "react";
import feature from "../../assets/fake-data/landingpage-three/feature";
import { Link } from "react-router-dom";

const Feature = ({mright,home}) => {
  //console.log(mright?.title)
  const feature2 = home?.filter((f) => f?.section === "Column1"|| f?.section === "Column2" || f?.section === "Column3" )
  //console.log(feature2)
  const [dataBlock] = useState({
    title: "The Best Awesome Startup Landing Tamplate",
    subTitle:
      " Due to its widespread use as filler text for layouts, non-readability is of great importance.",
  });
  return (
    <>
      <section className="py-20 bg-white">
        <div className="container">
          <div className="grid grid-cols-1">
            <div className="text-center">
              <h2 className="font-bold text-[32px] md:text-[40px] capitalize mb-2 relative z-10">
                <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                {mright?.title}
              </h2>
              <p className="text-muted mx-auto text-lg max-w-[600px] mt-6">
                {mright?.desc}
              </p>
            </div>
          </div>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-[30px] mt-16">
            {feature2?.map((x,idx) => {
              return (
                <div key={idx} className="text-center p-4 sm:px-14 lg:px-4">
                  <h5 className="font-bold text-xl">{x.title}</h5>
                  <p className="text-muted mt-4">{x.desc}</p>
                  <div className="mt-4">
                    <Link to="#">
                      <i className="mdi mdi-chevron-double-right text-white bg-custom rounded-full border-solid border border-custom inline-block h-[50px] w-[50px] text-xl leading-[50px]"></i>
                    </Link>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default Feature;
