import React from 'react'
import FooterOne from '../components/footers/FooterOne'
import MainHeroSection from '../features/common/MainHeroSection'
import LogMainSection from '../features/documentation/LogMainSection'

const ChangeLog = () => {
  return (
    <>
      <MainHeroSection title="Changelog" subTitle="What's new in the latest version of Insight." />
      <LogMainSection/>
      <FooterOne/>
    </>
  )
}

export default ChangeLog
