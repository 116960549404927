import React, {  useEffect,Fragment, useState }  from "react";
import axios from 'axios';
import swal from "sweetalert";
import { format } from 'date-fns';


export default function Modal({showModal,setShowModal}) {




  return (
    <> 

      {showModal ? (
        <>
      
             <div
            className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
             
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">


          {/*header*/}
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t ">
                  <h3 className="text-3xl font-semibold">
                    Privacy Policy
                  </h3>
                  <button
                    className=" ml-auto bg-black border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => {setShowModal(false);}}
                  >
                    <span className="bg-white text-black pb-2 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                
              <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t ">
                      
                {/*body*/}
              
<br />
  This Privacy Policy describes Our policies and procedures on the collection, use and disclosure of  information in compliance with NDPR standard. This site and it's data privacy policy is currently under review by accredited DPCO for necessary suggestions and adjustments.
  <br /><br />

  We use Your Personal data solemnly for the stated purpose of protecting your phones and returning of stolen but recovered phones to the actual owner and to provide an improve Services. By using the Service, You read to the end in the terms and conditions section and agree to the collection and use of your information as categorized and defined in details in the full version in accordance with this Privacy Policy.
  <br /><br />
            </div>
            <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {setShowModal(false);}}
                  >
                    Ok
                  </button>
                
                  {/* <button
                    className="bg-emerald-500 text-red active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Save Changes
                  </button> */}
                </div>
              </div>
            </div>
          </div> 
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
 
        </>
      ) : null}
    </>
  );
}