import React from "react";
import FooterOne from "../components/footers/FooterOne";
import FAQ from "../features/common/FAQ";
import SmartFeature from "../features/common/SmartFeature";
import MainHeroSection from "../features/common/MainHeroSection";

const FAQPage = () => {
  return (
    <>
      <MainHeroSection title="FAQ" subTitle="If you don't see an answer to your question, you can send us an email from our contact form." />
      <SmartFeature/> 
      <FAQ/>
      <FooterOne/>
    </>
  );
};

export default FAQPage;
