import React, { useEffect } from "react";
import { NavLink, useLocation, NavNavLink, matchPath } from "react-router-dom";
import logo from "../../../src/assets/images/logo-dark.png";

const Header = () => {
  const location = useLocation();

  const menuActive = () => {
    const menu = document.querySelector(".mobile-menu");
    menu.classList.toggle("hidden");
  };

  return (
    <div>
      <header>
        <nav className="navbar navbar-expand-lg navbar-light fixed w-full horizontal-nav">
          <div className="container">
            <div className="flex items-center justify-between">
              <NavLink className="navbar-brand" to="/landing/">
                <img src={logo} className="mr-2 w-[200px]" alt="logo" />
              </NavLink>
              <button
                onClick={() => menuActive()}
                className="navbar-toggler hidden text-end mobile-menu-button"
              >
                <span className="navbar-toggler-icon">
                  <i className="ti-menu icon-align-right"></i>
                </span>
              </button>

              <div
                className="navbar-collapse flex-1 mobile-menu hidden min-[991px]:block"
                id="navbarNav"
              >
                
                <ul className="navbar-nav justify-end horizontal-menu">
                <button  className=" ">
                <NavLink to="/landing" className="font-normal text-sm mr-2 rounded-full capitalize top-1 right-1 outline-none py-[11px] px-[15px] hover:bg-[#07897e] hover:border-[#07897e] duration-500 bg-custom text-white border border-custom">Home</NavLink>
                  </button>
                  {/* <li className="scroll dropdown group/menu">
                    <NavLink
                      className={({ isActive }) => {
                        return location.pathname.match("/landing")
                          ? "active dropdown-toggle"
                          : "dropdown-toggle";
                      }}
                    >
                      <span>Landing</span>
                    </NavLink>
                    <ul className="dropdown-menu hidden group-hover/menu:block">
                      <li>
                        <NavLink to="/landing/">Overview</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page1">Landing Page 1</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page2">Landing Page 2</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page3">Landing Page 3</NavLink>
                      </li>
                      <li>
                        <NavLink to="/landing/page4">Landing Page 4</NavLink>
                      </li>
                    </ul>
                  </li> */}
                  <li className="scroll dropdown group/menu">
                    <NavLink
                      className={({ isActive }) => {
                        return location.pathname.match("/pages")
                          ? "active dropdown-toggle"
                          : "dropdown-toggle";
                      }}
                    >
                      <span>Who We Are</span>
                    </NavLink>
                    <ul className="dropdown-menu hidden group-hover/menu:block">
                      <li>
                        <NavLink to="/pages/">About Us</NavLink>
                      </li>
                     
                      <li>
                        <NavLink to="/pages/">Directors</NavLink>
                      </li>
                      <li>
                        <NavLink to="/pages/">Partners</NavLink>
                      </li>

                      
                      

                      <li>
                        <NavLink to="/pages/contact">Contact</NavLink>
                      </li>
                    </ul>
                  </li>

                  <li className="scroll dropdown group/menu">
                    <NavLink
                      className={({ isActive }) => {
                        return location.pathname.match("/pages")
                          ? "active dropdown-toggle"
                          : "dropdown-toggle";
                      }}
                    >
                      <span>What We Do</span>
                    </NavLink>
                    <ul className="dropdown-menu hidden group-hover/menu:block">
                    <li>
                        <NavLink to="/pages/servicies">Service</NavLink>
                      </li>
                      {/* <li className="dropdown group/submenu">
                        <NavLink
                          className={({ isActive }) => {
                            return location.pathname.match("/pages/widget")
                              ? "active dropdown-toggle"
                              : "dropdown-toggle";
                          }}
                        >
                          Widget
                        </NavLink>
                        <ul className="dropdown-menu hidden group-hover/submenu:block">
                          <li>
                            <NavLink to="/pages/widget/">Pricing</NavLink>
                          </li>
                          <li>
                            <NavLink to="/pages/widget/testimonials">
                              Testimonial
                            </NavLink>
                          </li>
                          <li>
                            <NavLink to="/pages/widget/faq">FAQs</NavLink>
                          </li>
                        </ul>
                      </li> */}
                     
                      
                      
                    </ul>
                  </li>
                  {/* <li className="scroll dropdown group/menu">
                    <NavLink
                      className={({ isActive }) => {
                        return location.pathname.match("/pages")
                          ? "active dropdown-toggle"
                          : "dropdown-toggle";
                      }}
                    >
                      <span>Blog</span>
                    </NavLink>
                    <ul className="dropdown-menu hidden group-hover/menu:block">
                      <li>
                        <NavLink to="/pages/">Articles</NavLink>
                      </li>
                      <li>
                        <NavLink to="/pages/">Videos</NavLink>
                      </li>
                      <li>
                        <NavLink to="/pages/">Photo Gallery</NavLink>
                      </li>
  
                    </ul>
                  </li> */}
                  <li className="scroll dropdown group/menu">
                    <NavLink
                     
                      className={({ isActive }) => {
                        return location.pathname.match("/account")
                          ? "active dropdown-toggle"
                          : "dropdown-toggle";
                      }}
                    >
                      <span>M-Right Protection</span>
                    </NavLink>
                    <ul className="dropdown-menu hidden group-hover/menu:block">
                      <li>
                        {/* <NavLink to="/account/">Login</NavLink> */}
                        <a href="https://clients.mright.com.ng" target='_self'>Login</a>
                      </li>
                      <li>
                        {/* <NavLink to="/account/register">Sign Up</NavLink> */}
                        <a href="https://clients.mright.com.ng" target='_self'>Sign Up</a>
                      </li>
                      <li>
                        {/* <NavLink to="/account/pwreset">Password Reset</NavLink> */}
                        <a href="https://clients.mright.com.ng" target='_self'>Reset Password</a>
                      </li>
                    </ul>
                  </li>

                  
                  {/* <li className="scroll dropdown group/menu">
                    <NavLink
                     
                      className={({ isActive }) => {
                        return location.pathname.match("/documentation")
                          ? "active dropdown-toggle"
                          : "dropdown-toggle";
                      }}
                    >
                      <span>Docs</span>
                    </NavLink>
                    <ul className="dropdown-menu hidden group-hover/menu:block">
                      <li>
                        <NavLink to="/documentation/">Know How</NavLink>
                      </li>
                      <li>
                        <NavLink to="/documentation/changelog">
                          Notice Board
                        </NavLink>
                      </li>
                    </ul>
                  </li> */}
                  {/* <button className="btn btn-sm btn-custom navbar-btn btn-rounded">
                    Buy Now
                  </button> */}
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </header>
    </div>
  );
};

export default Header;
