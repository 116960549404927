import React from "react";
import aboutImg from '../../assets/images/about_page_img.jpg'

const AboutSection = ({image,subTitle,subTitle2,title,titleSmall2,titleSmall}) => {
  return (
    <>
      <section className="py-20 bg-white">
        <div className="container">
          <div className="grid grid-cols-1">
            <div>
              <img
                src={image}
                className="rounded-[30px] mx-auto block"
                alt="about-image"
              />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 mt-16 gap-[30px]">
            <div>
              <h2 className="font-bold capitalize text-[32px]">
                {title}
              </h2>
              <div className="mt-6">
                <p className="mb-1 text-muted">
                  <i className="mdi mdi-check-circle text-custom text-xl relative top-[2px]"></i>{" "}
                 {titleSmall2}
                </p>
                <p className="mb-0 text-muted">
                  <i className="mdi mdi-check-circle text-custom text-xl relative top-[2px]"></i>{" "}
                  {titleSmall}
                </p>
              </div>
            </div>
            <div>
              <p className="text-muted mb-4">
            {subTitle}
              </p>
              <p className="text-muted">
              {subTitle2}
              </p>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default AboutSection;
