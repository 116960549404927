import React, { useEffect, useState, useRef  } from 'react'
import { createClient } from "contentful";
import FooterThree from "../components/footers/FooterThree";
import ContentSection from "../features/common/ContentSection";
import PricingPlan from "../features/common/PricingPlan";
import Feature from "../features/landingpage-three/Feature";
import GetInTouch from "../features/landingpage-three/GetInTouch";import LogoSection from "../features/landingpage-three/LogoSection";
import TestModal from "./TestModal";
import Privacy from "./Privacy";
import ModalVideo from "react-modal-video";
import '../../node_modules/react-modal-video/scss/modal-video.scss'
import { Link } from "react-router-dom";
//import useContentful from "../components/hooks.js/useContentful";
//{section: 'Welcome Section', title: 'Welcome to Info-Tech Educational development initiative, IEDDV.', desc: 'IEDDV,is a non governmental organization that\ncrea…l economy and cashless policy era in the country.'}
import swal from "sweetalert";

const LandingPageThree = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [home, setHome] = useState([]);
  const [partners, setPartners] = useState([]);
  const [contact, setContact] = useState([]);
  const [browser, setBrowser] = useState();
  const [showModal, setShowModal] = useState(false);
 // const { getHome, getPartners } = useContentful();
 const [dataBlock2] = useState({
  title: "With  M-RIGHT, you can access your online contact instantly with another phone, when yours' run out of battery, missing or stolen.",
  subTitle:
    "Are you on emergency situation of missing/ stolen phone or your phone run out of battery? No need to worry and recharge battery or wait for 2, 3 days to welcome back your line to reestablish contact. Just Click on the emergency contact access link below with any phone or system available with you to proceed with Business.",
});

function getBrowserType() {
  const test = regexp => {
    return regexp.test(navigator.userAgent);
  };

  //console.log(navigator.userAgent);

  if (test(/opr\//i) || !!window.opr) {
    return 'Opera';
  } else if (test(/edg/i)) {
    return 'Microsoft Edge';
  } else if (test(/chrome|chromium|crios/i)) {
    return 'Google Chrome';
  } else if (test(/firefox|fxios/i)) {
    return 'Mozilla Firefox';
  } else if (test(/safari/i)) {
    return 'Apple Safari';
  } else if (test(/trident/i)) {
    return 'Microsoft Internet Explorer';
  } else if (test(/ucbrowser/i)) {
    return 'UC Browser';
  } else if (test(/samsungbrowser/i)) {
    return 'Samsung Browser';
  } else {
    return 'Unknown browser';
  }
}

function useDeviceDetect() {
  const [isTouchDevice, setIsTouchDevice] = useState(false);
  useEffect(() => {
    if (!window.matchMedia) return;
    setIsTouchDevice(window.matchMedia("(pointer:coarse)").matches);
  }, []);

  return isTouchDevice;
}
const isTouchDevice = useDeviceDetect();
const onClick = ()=> {

  // e.preventDefault();

  const browserType = getBrowserType();

  if(!isTouchDevice){
    swal(`
    Attention!

1. This service require login to Gmail account. When log on, click on  menu in the top right, then contact.

2. It's also works with only laptop or desktop computer due to Google's security restrictions of not accessing one email account with different phones. 
    
    `);
    window.location.replace('https://contacts.google.com/');
  }

   else {
    alert(`
    Attention!

1. This service require login to Gmail account. When log on, click on  menu in the top right, then contact.

2. It's also works with only laptop or desktop computer due to Google's security restrictions of not accessing one email account with different phones. 
    
    `);
  
    window.location.replace('https://contacts.google.com/');
  
  }

//   if(browserType ==='Google Chrome'){
//     swal(`
//     Attention!

// 1. This service require login to Gmail account. When log on, click on  menu in the top right, then click 'contact' in the dropped down menu to see your contact.

// 2. This function is not working in Chrome browser. Use Opera or any other browser, type www.mright.com.ng to enjoy the service. 

// Don't have other browser? Install from playstore.
    
//     `);
  
//   }

//    else {
//     alert(`  Attention!

//     1. This service require login to Gmail account. When log on, click on  menu in the top right, then click 'contact' in the dropped down menu to see your contact.
    
//    `);
  
//     window.location.replace('https://contacts.google.com/');
  
//   }
  
  
}
const onClick2 = ()=> {


    swal(`

    Attention!

    This function is awaiting integration by the telecom operators such as mtn, Airtel, glo etc.
    
    `);
  
 
  
}




 const client = createClient({
  //space: process.env.REACT_APP_SPACE_ID,
  space: "vcapyi6fg0e5",
 // accessToken: process.env.REACT_APP_ACCESS_TOKEN,
  accessToken: "xpa9NUxBNUIsWaiJNltFGuirPfhhXvXIAJJpNZcgnUg",
  host: "preview.contentful.com"
});


const content2 = home.find((element) => {
  return element.section==='Section 2';
})



const content3 = home.find((element) => {
  return element.section==='Section 3';
})

const contactHome = contact.find((element) => {
  return element.section==='Section1';
})
   //console.log(contactHome)
    //await getPartners().then((response) => response && setPartners(response));
    const getHome = async () => {
      try {
        const entries = await client.getEntries({
          content_type: "home",
          select: "fields"
        });
    
        const sanitizedEntries = entries.items.map((item) => {
         // const avatar = item.fields.avatar?.fields;
          return {
            ...item.fields
          };
        });
    
        setHome(sanitizedEntries);
      } catch (error) {
        console.log(`Error fetching home ${error}`);
      }
    };
    
    
    const getPartners = async () => {
      try {
        const entries = await client.getEntries({
          content_type: "partners",
          select: "fields"
        });
    
        const sanitizedEntries = entries.items.map((item) => {
         // const avatar = item.fields.avatar?.fields;
          return {
            ...item.fields
          };
        });
    
        return sanitizedEntries;
      } catch (error) {
        console.log(`Error fetching home ${error}`);
      }
    };
    const getContact = async () => {
      try {
        const entries = await client.getEntries({
          content_type: "contactHomeSection",
          select: "fields"
        });
    
        const sanitizedEntries = entries.items.map((item) => {
         // const avatar = item.fields.avatar?.fields;
          return {
            ...item.fields
          };
        });
    
        setContact(sanitizedEntries);
      } catch (error) {
        console.log(`Error fetching home ${error}`);
      }
    };
     
    useEffect(() => {

      



   getHome()
   getPartners()
   getContact()


  
      
      
   
     },[]);





const welcome = home.find((element) => {
  return element.section==='Welcome Section';
})
const mright = home.find((element) => {
  return element.section==='Section1';
})
//console.log(welcome);
  const [dataBlock] = useState({
    title: 'welcome.title',
    subTitle:
      'home2.desc',
  });



  return (
    <>
      <section className="bg-white pt-[120px] mt-550 md:pt-[120px] md:pb-20">
        <div className="container">
   
        <TestModal/>
        <Privacy showModal={showModal}  setShowModal= {setShowModal}/>
        

        <div className="grid grid-cols-1 mt-24">
            <div className="text-center">
              <h2 className="font-bold text-[32px] md:text-[40px] capitalize mb-2 relative z-10">
                <span className=" h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                {dataBlock2.title}
              </h2>
              <p className="text-muted mx-auto text-lg max-w-[600px] mt-6">
                {dataBlock2.subTitle}
              </p><br />
              <p>
                 <button  onClick={onClick}   type='button' className=" font-bold text-sm mr-10 rounded-full capitalize top-1 right-1 outline-none py-[11px] px-[30px] hover:bg-[#07897e] hover:border-[#07897e] duration-500 bg-green text-black border border-custom">
                 emergency contact access
                  </button>
              </p>
            </div>
          </div>

        <div className="grid grid-cols-1 mt-16">
            <div
              id="tab-buttons1"
              className="flex items-center gap-3 justify-center"
            >
               
              
                  <a href="https://clients.mright.com.ng/login" 
                    className="font-semibold rounded-[5px] block  bg-white text-dark"
                  >
                   <button className="  font-bold text-sm mr-10 rounded-full capitalize top-1 right-1 outline-none py-[11px] px-[30px] hover:bg-[#07897e] hover:border-[#07897e] duration-500 bg-green text-black border border-custom">
                    LOGIN 
                  </button>
                  </a>
                  <a href="https://clients.mright.com.ng/page-register" 
                    className="font-semibold rounded-[5px] block  bg-white text-dark  "
                  >
                   <button className="  font-bold text-sm mr-10 rounded-full capitalize top-1 right-1 outline-none py-[11px] px-[30px] hover:bg-[#07897e] hover:border-[#07897e] duration-500 bg-green text-black border border-custom">
                    CREATE ACCOUNT 
                  </button>
                  </a>
                  {/* <a 
                    className="font-semibold rounded-[5px] block py-2 px-4 bg-white text-dark border-2 border-[#e7e7e7]/50"
                  >
                    tab 2
                  </a> */}
               
            </div>
            
          </div>
          <div><br></br></div>
          <div className="grid grid-cols-1">
            <div className="bg-light text-center py-10 md:py-[60px] px-[30px]">
              {/* <div className=" mx-auto mb-24 ">
              
                <h1 className="font-semibold leading-[1.4] text-[32px] md:text-[46px]">
              
                    
                    <form className="relative max-w-[380px] mx-auto mt-6">
                        <input type="text" className="form-input h-[52px] py-3.5 px-4 text-sm !pr-[160px] text-dark rounded-md bg-white border border-solid !border-[#ededed]" placeholder="Type Phone Serial Number"/>
                        <button className="absolute tracking-[1px] font-normal text-sm rounded-md capitalize top-1 right-1 outline-none py-[11px] px-[30px] hover:bg-[#07897e] hover:border-[#07897e] duration-500 bg-custom text-white border border-custom">Search</button>
                    </form>
                
                </h1>
             
               
                  
                  <a href="https://clients.mright.com.ng" target='_self'> <button className=" font-normal text-sm mr-10 rounded-full capitalize top-1 right-1 outline-none py-[11px] px-[30px] hover:bg-[#07897e] hover:border-[#07897e] duration-500 bg-custom text-white border border-custom">
                    LOGIN TO M-RIGHT SECURITY
                  </button></a>
                  <a href="https://clients.mright.com.ng" target='_self'>  <button className=" tracking-[1px] font-normal text-sm rounded-full capitalize top-1 right-1 outline-none py-[11px] px-[30px] hover:bg-[#07897e] hover:border-[#07897e] duration-500 bg-custom text-white border border-custom">
                   MEMBER REGISTRATION
                  </button></a>
                
               
              </div> */}
               {/* <div className="h-[3px] bg-custom w-14 inline-block mt-10 mb-5 mx-0"></div>
             <div className="mt-6">
                 
                
                 <button  onClick={onClick2}   type='button' className=" font-bold text-sm m-10 rounded-full capitalize top-1 right-1 outline-none py-[11px] px-[30px] hover:bg-[#07897e] hover:border-[#07897e] duration-500 bg-green text-black border border-custom">
                   block my phone
                 </button>
                 
           
               
             </div> */}
                  <div className="h-[3px] bg-custom w-14 inline-block mt-10 mb-5 mx-0"></div>
                  <div className="max-w-[650px] mx-auto">
           
                <h1 className="font-semibold leading-[1.4] text-[32px] md:text-[46px]">
                  {welcome?.title}
                </h1>
                <p className="text-muted mx-auto md:text-lg mt-4 max-w-[500px]">
                  {welcome?.desc}
                 
                </p>
                <div className="mt-6">
                  <button
                    onClick={() => setIsOpen(true)}
                    className="lightbox text-white"
                  >
                    <i className="mdi mdi-play bg-custom h-[66px] w-[66px] leading-[66px] text-[34px] shadow-[0px_0px_0px_6px_rgb(10,161,149,0.15)] rounded-full inline-block text-center"></i>
                  </button>
                  <ModalVideo
                    channel="youtube"
                    isOpen={isOpen}
                    videoId="YjhrligRTbE"
                    onClose={() => setIsOpen(false)}
                  />
                </div>
           
              </div>
            </div>
          </div>
        </div>
      </section>
      <Feature mright={mright} home={home}  />
      <ContentSection home={home} designDataBlock={content2} userDataBlock={content3} />
      {/* <LogoSection partners={partners} /> */}
      {/* <PricingPlan /> */}
      <GetInTouch contactHome={contactHome} />
      <FooterThree setShowModal={setShowModal} />
    </>
  );
};

export default LandingPageThree;
