import React from "react";
import faq from "../../assets/fake-data/landingpage-one/faq";
import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from "react-accessible-accordion";
import 'react-accessible-accordion/dist/fancy-example.css';

const FAQ = () => {
  return (
    <>
      <section className="py-20 bg-light">
        <div className="container">
          <div className="grid grid-cols-1">
            <div className="text-center">
              <h2 className="font-bold text-[32px] md:text-[40px] capitalize mb-2 relative z-10">
                <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                Want To Ask Something From Us?
              </h2>
              <p className="text-muted mx-auto text-lg max-w-[600px] mt-6">
                Due to its widespread use as filler text for layouts,
                non-readability is of great importance.
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 mt-16">
            <div className="mx-auto w-full max-w-[650px]">
              <Accordion className="faq space-y-2.5">
                {faq.map((x) => {
                  return (
                    <AccordionItem
                      key={x.id}
                      className="ac mt-0 bg-white border-none"
                    >
                      <AccordionItemHeading >
                        <AccordionItemButton
                          type="button"
                          className="ac-trigger !p-5 relative !flex w-full items-center justify-between gap-2 !text-lg !font-nunito !font-semibold !text-dark after:!hidden"
                        >
                          <span className="leading-[22px]">{x.question}</span>
                          <div className="leading-[22px]">
                            <i className="mdi mdi-plus plus text-xl font-bold leading-[0px]"></i>
                            <i className="mdi mdi-minus minus text-xl font-bold leading-[0px]"></i>
                          </div>
                        </AccordionItemButton>
                      </AccordionItemHeading>
                      <AccordionItemPanel className="ac-panel">
                        <p className="ac-text !p-[26px] !pt-[6px] !font-nunito !text-[15px] !font-normal !leading-[22px] !text-muted">
                          {x.answer}
                        </p>
                      </AccordionItemPanel>
                    </AccordionItem>
                  );
                })}
              </Accordion>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default FAQ;
