import React, { useEffect } from "react";
import SyntaxHighlighter from "react-syntax-highlighter";
import { docco } from "react-syntax-highlighter/dist/esm/styles/hljs";

const DocMainSection = () => {
  
    useEffect(() => {
      const sections = document.querySelectorAll("section");
      const navLi = document.querySelectorAll(".doc-nav li");
      window.onscroll = () => {
          var current = "";    
          sections.forEach((section) => {
              const sectionTop = section.offsetTop;
              if (window.scrollY >= sectionTop - 60) {
                  current = section.getAttribute("id");
              }
          });
    
          navLi.forEach((li) => { 
              li.classList.remove("active");
              if (li.classList.contains(current)) {
                  li.classList.add("active");
              }
          });
      };
    
    })

  const codeString = `
  import React, { Component } from "react";
  import { createBrowserRouter, RouterProvider} from "react-router-dom";
    
    
    function App() {
      const router = createBrowserRouter([
        {
          path: "landing",
          element: <LandingPageLayout />,
          children: [
            {
              path: "",
              element: <Overview />,
            },
            {
              path: "page1",
              element: <LandingPageOne />,
            },
            {
              path: "page2",
              element: <LandingPageTwo />,
            },
            {
              path: "page3",
              element: <LandingPageThree />,
            },
            {
              path: "page4",
              element: <LandingPageFour />,
            },
          ],
        },
      ]}

      return (
        <>
          <RouterProvider router={router} />
        </>
      );

      export default App;
    }`;

  return (
    <>
      <section className="py-20">
        <div className="container">
          <div className="grid grid-cols-1 lg:grid-cols-12 gap-[30px]">
            <div className="lg:col-span-3">
              <div className="sticky top-[90px]">
                <div className="border-2 border-[#f4f4f4] rounded-md mt-0">
                  <div className="p-[26px] border-b-2 border-[#f4f4f4]">
                    <h5 className="font-bold text-xl">Documentation</h5>
                  </div>
                  <nav className="p-[26px]">
                    <ul className="space-y-2.5 doc-nav">
                      <li className="intro">
                        <a href="#intro">Introduction</a>
                      </li>
                      <li className="installation">
                        <a href="#installation">Installation</a>
                      </li>
                      <li className="file_structure">
                        <a href="#file_structure">File Structure</a>
                      </li>
                      <li className="react">
                        <a href="#react">React</a>
                      </li>
                      <li className="css">
                        <a href="#css">CSS</a>
                      </li>
                      <li className="dependencies">
                        <a href="#dependencies">Dependencies</a>
                      </li>
                      <li className="features">
                        <a href="#features">Features</a>
                      </li>
                      <li className="credit">
                        <a href="#credit">Credit &amp; Resources</a>
                      </li>
                      <li className="support">
                        <a href="#support">Support</a>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>
            </div>
            <section className="lg:col-span-9 space-y-5">
              <section id="intro">
                <div className="border-2 border-[#f4f4f4] rounded-md mt-0">
                  <div className="p-[26px] border-b-2 border-[#f4f4f4]">
                    <h5 className="font-bold text-xl">Introduction</h5>
                  </div>
                  <div className="p-[26px]">
                    <p className="text-lg text-muted">
                      Landik is a superb, one of a kind, and present day React
                      Template having multi-choices for Startups, App Landing,
                      and SaaS-based companies. It has 4 special points of
                      arrival with multi internal pages for different purposes.
                    </p>
                  </div>
                </div>
              </section>
              <section id="installation">
                <div className="border-2 border-[#f4f4f4] rounded-md mt-0">
                  <div className="p-[26px] border-b-2 border-[#f4f4f4]">
                    <h5 className="font-bold text-xl">Installation</h5>
                  </div>
                  <div className="p-[26px]">
                    <p className="text-lg font-semibold">
                      To setup the admin theme, follow below-mentioned steps
                    </p>
                    <ul className="mt-3 list-decimal list-inside space-y-2">
                      <li>
                        The zip file contains all files integrated with Landik,
                        however you need to perform following steps to get
                        node_modules etc.
                      </li>
                      <li>
                        Note : Make sure this all installation process is done
                        in command prompt.
                      </li>
                      <li>
                        make sure you have installed tailwindcss in your os. If
                        not installed then please run the below command to
                        install it globally.
                        <code className="text-[#e83e8c]">npm install</code>
                      </li>
                      <li>
                        After Completion of installation Run the following
                        command in the command prompt to start tailwindcss
                        development server.
                        <code className="text-[#e83e8c]">npm start </code>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
              <section id="file_structure">
                <div className="border-2 border-[#f4f4f4] rounded-md mt-0">
                  <div className="p-[26px] border-b-2 border-[#f4f4f4]">
                    <h5 className="font-bold text-xl">File Structure</h5>
                  </div>
                  <div className="p-[26px] overflow-x-auto w-full">
                    <pre className="prettyprint">
                      <i className="mdi mdi-folder-multiple"></i> LANDIK-REACT/{" "}<br />
                      ├── <i className="mdi mdi-folder-multiple"></i> landik/ <br />
                      │   ├── <i className="mdi mdi-folder-multiple"></i>{" "} node_modules/ <br />
                      │   ├── <i className="mdi mdi-folder-multiple"></i> public/ <br />
                      │   │   ├── <i className="mdi mdi-folder-multiple"></i> favicon.ico{" "}<br />
                      │   │   ├── <i className="mdi mdi-folder-multiple"></i> index.html <br />
                      │   ├── <i className="mdi mdi-folder-multiple"></i> src/ <br />
                      │   │   ├── <i className="mdi mdi-folder-multiple"></i> assets/ <br />
                      │   │   │   ├── <i className="mdi mdi-folder-multiple"></i> css/ <br />
                      │   │   │   │ └── <i className="mdi mdi-file-multiple"></i> All css files <br />
                      │   │   │   ├──<i className="mdi mdi-folder-multiple"></i> fake-data/ <br />
                      │   │   │   │ └── <i className="mdi mdi-file-multiple"></i> All fake data <br />
                      │   │   │   ├── <i className="mdi mdi-folder-multiple"></i> images/ <br />
                      │   │   │   │ └── <i className="mdi mdi-file-multiple"></i> All images <br />
                      │   │   ├── <i className="mdi mdi-folder-multiple"></i>  components/ <br />
                      │   │   │   ├── <i className="mdi mdi-folder-multiple"></i> footer/ <br />
                      │   │   │   │ └── <i className="mdi mdi-file-multiple"></i> All common footer <br />
                      │   │   │   ├──<i className="mdi mdi-folder-multiple"></i> header/ <br />
                      │   │   │   │ └── <i className="mdi mdi-file-multiple"></i> common header <br />
                      │   │   ├── <i className="mdi mdi-folder-multiple"></i> features/ <br />
                      │   │   │   ├── <i className="mdi mdi-folder-multiple"></i> All pages features <br />
                      │   │   ├── <i className="mdi mdi-folder-multiple"></i> layout/ <br />
                      │   │   │   ├── <i className="mdi mdi-folder-multiple"></i> All files for layout <br />
                      │   │   ├── <i className="mdi mdi-folder-multiple"></i> pages/ <br />
                      │   │   │   ├── <i className="mdi mdi-folder-multiple"></i> All React Pages <br />
                      │   │   ├── <i className="mdi mdi-folder-multiple"></i> App.js/ <br />
                      │   │   │   ├── <i className="mdi mdi-folder-multiple"></i> All Routes with layout <br />
                      │   │   ├── <i className="mdi mdi-folder-multiple"></i> index.js/ <br />
                      │   │   │   ├── <i className="mdi mdi-folder-multiple"></i> Main file <br />
                      │   └──  <i className="mdi mdi-file-multiple"></i> package-lock.json <br />
                      │   └── <i className="mdi mdi-file-multiple"></i> package.json <br />
                      │   └── <i className="mdi mdi-file-multiple"></i> tailwind.config.js
                      <br />
                    </pre>
                  </div>
                </div>
              </section>
              <section id="react">
                <div className="border-2 border-[#f4f4f4] rounded-md mt-0">
                  <div className="p-[26px] border-b-2 border-[#f4f4f4]">
                    <h5 className="font-bold text-xl">React</h5>
                  </div>
                  <div className="p-[26px] overflow-x-auto w-full">
                    <SyntaxHighlighter language="javascript" style={docco}>
                      {codeString}
                    </SyntaxHighlighter>
                  </div>
                </div>
              </section>
              <section id="css">
                <div className="border-2 border-[#f4f4f4] rounded-md mt-0">
                  <div className="p-[26px] border-b-2 border-[#f4f4f4]">
                    <h5 className="font-bold text-xl">CSS</h5>
                  </div>
                  <div className="p-[26px]">
                    <p className="text-lg font-semibold">
                      Landik Built with latest version of CSS3.
                    </p>
                    <div className="overflow-x-auto w-full mt-4">
                      <table className="w-full m-0 border whitespace-nowrap border-[#dee2e6]">
                        <thead>
                          <tr className="text-left">
                            <th className="p-3 border border-[#dee2e6]">
                              File Name
                            </th>
                            <th className="p-3 border border-[#dee2e6]">
                              Details
                            </th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td className="p-3 border border-[#dee2e6]">
                              <code className="text-[#e83e8c]">
                                tailwind.css
                              </code>
                            </td>
                            <td className="p-3 border border-[#dee2e6]">
                              Tailwind CSS
                            </td>
                          </tr>  
                          <tr>
                            <td className="p-3 border border-[#dee2e6]">
                              <code className="text-[#e83e8c]">
                                materialdesignicons.css
                              </code>
                            </td>
                            <td className="p-3 border border-[#dee2e6]">
                            Materialdesignicons css
                            </td>
                          </tr>  
                          <tr>
                            <td className="p-3 border border-[#dee2e6]">
                              <code className="text-[#e83e8c]">
                                themyfy-icons.css
                              </code>
                            </td>
                            <td className="p-3 border border-[#dee2e6]">
                            Themyfy css
                            </td>
                          </tr>  
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </section>
              <section id="dependencies">
                <div className="border-2 border-[#f4f4f4] rounded-md mt-0">
                  <div className="p-[26px] border-b-2 border-[#f4f4f4]">
                    <h5 className="font-bold text-xl">Dependencies</h5>
                  </div>
                  <div className="p-[26px]">
                    <p className="text-lg font-semibold">
                      We are come with following dependencies.
                    </p>
                    <ul className="mt-3 list-inside list-disc space-y-2 text-muted">
                      <li>react-accessible-accordion</li>
                      <li>react-countup</li>
                      <li>react-modal-video</li>
                      <li>react-syntax-highlighter</li>
                      <li>swiper</li>
                    </ul>
                  </div>
                </div>
              </section>
              <section id="features">
                <div className="border-2 border-[#f4f4f4] rounded-md mt-0">
                  <div className="p-[26px] border-b-2 border-[#f4f4f4]">
                    <h5 className="font-bold text-xl">Features</h5>
                  </div>
                  <div className="p-[26px]">
                    <p className="text-lg font-semibold">
                      We are come with following features.
                    </p>
                    <ul className="mt-3 list-inside list-disc space-y-2 text-muted">
                      <li>
                        Based on TailwindCSS <b>v3.2.4.</b>
                      </li>
                      <li>
                        <b>4</b> Diffrent Landing Pages
                      </li>
                      <li>Built with HTML5 & CSS3</li>
                      <li>Font Icons</li>
                      <li>Google Fonts</li>
                      <li>Easy to Customize</li>
                      <li>Clean code</li>
                      <li>Browser Compatibility</li>
                      <li>Fully Customizable</li>
                      <li>Modern Design</li>
                      <li>And much more…</li>
                    </ul>
                  </div>
                </div>
              </section>
              <section id="credit">
                <div className="border-2 border-[#f4f4f4] rounded-md mt-0">
                  <div className="p-[26px] border-b-2 border-[#f4f4f4]">
                    <h5 className="font-bold text-xl">Credit & Resources</h5>
                  </div>
                  <div className="p-[26px]">
                    <p className="text-lg font-semibold">
                      We have used the following plugins.
                    </p>
                    <ul className="mt-3 list-inside list-disc space-y-2 text-muted">
                      <li>
                        <a href="https://tailwindcss.com/">Tailwind Css</a>
                      </li>
                      <li>
                        <a href="https://github.com/olimsaidov/pixeden-stroke-7-icon">
                          Pe 7s Icons
                        </a>
                      </li>
                      <li>
                        <a href="https://materialdesignicons.com/">
                          Materialdesign Icons
                        </a>
                      </li>
                      <li>
                        <a href="https://themify.me/themify-icons">
                          Themify Icons
                        </a>
                      </li>
                      <li>
                        <a href="https://undraw.co/">Undraw Images</a>
                      </li>
                      <li>
                        <a href="https://swiperjs.com/">Swiper Js</a>
                      </li>
                      <li>
                        <a href="https://unsplash.com/">Unsplash</a>
                      </li>
                      <li>
                        <a href="https://dribbble.com/">Dribbble</a>
                      </li>
                      <li>
                        <a href="https://graphicburger.com/6-vintage-logo-badges-2/">
                          Client Logo
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </section>
              <section id="support">
                <div className="border-2 border-[#f4f4f4] rounded-md mt-0">
                  <div className="p-[26px] border-b-2 border-[#f4f4f4]">
                    <h5 className="font-bold text-xl">Support</h5>
                  </div>
                  <div className="p-[26px]">
                    <p className="text-lg font-semibold">
                      Thank you for become a part of us. If you have any query,
                      suggestion and complain. Contact us anytime.
                    </p>
                    <p className="text-custom mt-3 text-lg font-semibold">
                      - support@xcodethemes.in
                    </p>
                  </div>
                </div>
              </section>
            </section>
          </div>
        </div>
      </section>
    </>
  );
};

export default DocMainSection;
