import React from "react";
import FooterThree from "../components/footers/FooterThree";
import ContactSection from "../features/contact/ContactSection";
import MainHeroSection from "../features/common/MainHeroSection";

const ContactPage = () => {
  return (
    <>
      <MainHeroSection title="Have a project in mind?" subTitle="We'd love to talk about how we can help you." />
      <ContactSection />
      <FooterThree />
    </>
  );
};

export default ContactPage;
