import React, { useState } from 'react'
import heartIcon from "../../assets/images/icon/heart.svg"
import { Link } from 'react-router-dom'

const FooterThree = ({setShowModal}) => {
    const [dataBlock] = useState({
        title: "Subcribe Our Newslatter"
    })
  return (
    <>
       <section className="pt-4 pb-[22px]">
        <div className="container">
            <div className="grid grid-cols-1 md:grid-cols-6 gap-[30px]">
                <div className="md:col-span-3">
                    <h6 className="mt-4 text-lg font-bold">{dataBlock.title}</h6>
                    <div className="bg-custom h-[2px] w-[26px] mt-2"></div>
                    <form className="relative max-w-[380px] mt-6">
                        <input type="text" className="form-input h-[52px] py-3.5 px-4 text-sm !pr-[160px] text-dark rounded-md bg-white border border-solid !border-[#ededed]" placeholder="Email Address"/>
                        <button disabled={true} className="absolute tracking-[1px] font-normal text-sm rounded-md capitalize top-1 right-1 outline-none py-[11px] px-[30px] hover:bg-[#07897e] hover:border-[#07897e] duration-500 bg-custom text-white border border-custom">Get Started</button>
                    </form>
                </div>
                <div>
                    <h6 className="mt-4 text-lg font-bold">Social</h6>
                    <div className="bg-custom h-[2px] w-[26px] mt-2"></div>
                    <ul className="mt-6 text-dark/70 space-y-3 text-sm">
                        <li><Link to="#" className="hover:text-custom inline-block transition-all duration-200">Blog</Link></li>
                        <li><Link to="#" className="hover:text-custom inline-block transition-all duration-200">Facebook</Link></li>
                        <li><Link to="#" className="hover:text-custom inline-block transition-all duration-200">Instagram</Link></li>
                        <li><Link to="#" className="hover:text-custom inline-block transition-all duration-200">Twitter</Link></li>
                    </ul>
                </div>
                <div>
                    <h6 className="mt-4 text-lg font-bold">Product</h6>
                    <div className="bg-custom h-[2px] w-[26px] mt-2"></div>
                    <ul className="mt-6 text-dark/70 space-y-3 text-sm">
                        <li><Link to="#" className="hover:text-custom inline-block transition-all duration-200">Service</Link></li>
                        <li><Link to="#" className="hover:text-custom inline-block transition-all duration-200">Features</Link></li>
                        <li><Link to="#" className="hover:text-custom inline-block transition-all duration-200">Credit</Link></li>
                        <li><Link to="#" className="hover:text-custom inline-block transition-all duration-200">FAQ</Link></li>
                    </ul>
                </div>
                <div>
                    <h6 className="mt-4 text-lg font-bold">Support</h6>
                    <div className="bg-custom h-[2px] w-[26px] mt-2"></div>
                    <ul className="mt-6 text-dark/70 space-y-3 text-sm">
                        <li><Link to="#" className="hover:text-custom inline-block transition-all duration-200" onClick={()=>setShowModal(true)}>Privacy</Link></li>
                        <li><Link to="#" className="hover:text-custom inline-block transition-all duration-200">Tearms</Link></li>
                        <li><Link to="#" className="hover:text-custom inline-block transition-all duration-200">Help</Link></li>
                    </ul>
                </div>
            </div>
            <div className="bg-dark/[0.04] h-[1px] mt-[50px] mb-[22px] w-full inline-block"></div>
            <div className="grid grid-cols-1">
                <div className="text-center">
                    <div className="w-[50px] h-[50px] bg-custom/20 rounded-full leading-[50px] flex items-center justify-center mx-auto">
                        <img src={heartIcon} alt="heart" className="h-[30px] mx-auto"/>
                    </div>
                    <p className="mt-6 text-muted">&copy; {`${new Date().getFullYear()} MRight DigitalTech Limited. Analysis and Design by A. M. Ali and coded by `}<a href='https://www.lamydas.com' target="_blank" rel="noreferrer">lamydas Solutions</a> </p>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default FooterThree
