import React, { useState } from "react";
import ModalVideo from "react-modal-video";
import '../../../node_modules/react-modal-video/scss/modal-video.scss'

const GetStarted = () => {
  const [isOpen, setIsOpen] = useState(false);

  const [dataBlock] = useState({
    title: "Get Started with IEDDV",
    subTitle:
      " Due to its Creation of general awareness, inculcation of open basic digital literacy, cyber security measures and advice for successful implementation of policies especially In this digital economy and cashless policy era in the country.",
  });
  return (
    <>
      <section className="py-20 bg-white relative">
        <div className="container">
          <div className="grid grid-cols-1">
            <div className="text-center rounded-[15px] py-[60px] px-[30px] bg-custom relative text-white">
              <div className="bg-[url(../images/wave-mask.png)] absolute inset-0 bg-cover bg-center bg-no-repeat w-full h-full"></div>
              <h2 className="font-bold text-[40px] capitalize relative z-10">
                {dataBlock.title}
              </h2>
              <p className="mx-auto max-w-[600px] text-lg leading-[1.5] mt-6 text-[#f7f7f7] relative">
                {dataBlock.subTitle}
              </p>
              <div className="mt-10 relative z-10">
                <div className="text-center">
                  <button
                    onClick={() => setIsOpen(true)}
                    className="lightbox border-[3px] inline-block rounded-full p-[7px]"
                  >
                    <i className="mdi mdi-play presentation_icon rounded-full bg-white inline-block text-center text-custom text-[35px] leading-[65px] duration-200 h-[65px] w-[65px]"></i>
                  </button>
                  <ModalVideo
                  channel="youtube"
                  isOpen={isOpen}
                  videoId="YjhrligRTbE"
                  onClose={() => setIsOpen(false)}
                />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default GetStarted;
