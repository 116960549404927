const services = [
  {
    id: 1,
    icon: "mdi mdi-compass-rose",
    title: "Easy access",
    subTitle:
      "Take Ehya wherever you go so that you know what’s going on with your money at all times.",
  },
  {
    id: 2,
    icon: "mdi mdi-drag-variant",
    title: "Budgets that work",
    subTitle:
      "Create budgets you can actually stick to, and can actually see how you’re spending your money.",
  },
  {
    id: 3,
    icon: "mdi mdi-google-circles-extended",
    title: "Total control",
    subTitle:
      "Take a full control of your expenses. Landy will control of help you to do that anywhere anytime.",
  },
];

export default services