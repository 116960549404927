import React from 'react'
import team from '../../assets/fake-data/team'
import { Link } from 'react-router-dom'

const TeamSection = ({title,desc,image2}) => {
  return (
    <>
          <section className="py-5 bg-white">
        <div className="container">
            <div className="grid grid-cols-1 mb-5">
                <div className="text-center ">
                    <h2 className="font-bold text-[32px] md:text-[40px] capitalize mb-2 relative z-10"><span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>{title}</h2>
                    <p className="text-muted mx-auto text-lg max-w-[600px] mt-6">{desc}</p>
                </div>
            </div>
            <div className="grid grid-cols-1">
                <div className="text-center mt-8">
                <img
                src={image2}
                className="rounded-[30px] mx-auto block"
                alt="about-image"
              />
                </div>
            </div>
            {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 mt-16 gap-[30px]">
            {team.map((x) => {
                return  <div key={x.id} className="text-center">
                    <img src={x.image} className="mx-auto rounded-[30px] block"/>
                    <div className="mt-4">
                        <h4 className="font-bold text-2xl">{x.name}</h4>
                        <p className="text-muted mt-2">{x.role}</p>
                    </div>
                    <div className="bg-[#e7e7e7] h-px w-4/5 mx-auto my-5"></div>
                    <div>
                        <ul className="flex items-center space-x-3 justify-center">
                            <li className="shrink-0">
                                <Link to="#" className="text-dark hover:text-custom duration-300 px-2 leading-10 rounded-full block text-[26px] text-center"><i className="mdi mdi-twitter"></i></Link>
                            </li>
                            <li className="shrink-0">
                                <Link to="#" className="text-dark hover:text-custom duration-300 px-2 leading-10 rounded-full block text-[26px] text-center"><i className="mdi mdi-google-plus"></i></Link>
                            </li>
                            <li className="shrink-0">
                                <Link to="#" className="text-dark hover:text-custom duration-300 px-2 leading-10 rounded-full block text-[26px] text-center"><i className="mdi mdi-linkedin"></i></Link>
                            </li>
                        </ul>
                    </div>
                </div>
            })}
            </div> */}
        </div>
    </section>
    </>
  )
}

export default TeamSection