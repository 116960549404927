import React, { useState } from "react";
import allImageOfClient from "../../assets/fake-data/clientImages";


const ClientLogo = ({blogLogo}) => {
  console.log(blogLogo)
  const [dataBlock] = useState({
    title: "10,000+ Companies",
    subTitle:
      "Due to its widespread use as filler text for layouts, non-readability is of great importance.",
  });


  return (
    <>
      <section className="pt-20 pb-36 bg-white relative">
        <div className="container">
          {/* <div className="grid grid-cols-1">
            <div className="text-center">
              <h2 className="font-bold text-[32px] md:text-[40px] capitalize mb-2 relative z-10">
                <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                {dataBlock.title}
              </h2>
              <p className="text-muted mx-auto text-lg max-w-[600px] mt-6">
                {dataBlock.subTitle}
              </p>
            </div>
          </div> */}
          <div className="grid grid-cols-1 md:grid-cols-4  rounded mt-12 py-10 gap-[30px] items-center">
            {blogLogo?.map((x) => {
              return (
                <div key={x.id} className="p-5">
                  <img src={x.logo.fields.file.url} alt="logo-img" className="mx-auto block" />
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default ClientLogo;
