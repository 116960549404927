import React, { useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Pagination } from "swiper";
import coustomer from "../../assets/fake-data/landingpage-two/coustomer";

const Coustomer = () => {
  const [dataBlock] = useState({
    title: "Customers Loving It",
    subTitle:
      "Due to its widespread use as filler text for layouts, non-readability is of great importance.",
  });
  return (
    <>
      <section className="py-20">
        <div className="container">
          <div className="grid grid-cols-1">
            <div className="text-center">
              <h2 className="font-bold text-[32px] md:text-[40px] capitalize mb-2 relative z-10">
                <span className="bg-[#dff1f0] h-[70px] w-[70px] inline-block absolute rounded-full -z-10 -top-[15px]"></span>
                {dataBlock.title}
              </h2>
              <p className="text-muted mx-auto text-lg max-w-[600px] mt-6">
                {dataBlock.subTitle}
              </p>
            </div>
          </div>
          <div className="grid grid-cols-1 mt-16">
            <div className="relative client-sec">
              <Swiper
                modules={[Pagination]}
                delay={3000}
                slidesPerView={1}
                loop={true}
                pagination={{ el: ".swiper-pagination-center" }}
                autoplay={true}
                breakpoints={{
                  768: {
                    slidesPerView: 2,
                    spaceBetween: 30,
                  },
                }}
              >
                <div className="swiper-wrapper">
                  {coustomer.map((item) => (
                    <SwiperSlide key={item.id} >
                      <div className="border border-[#e7e7e7]/70 py-[26px] px-4 rounded-md">
                        <div className="relative text-custom text-center">
                          <img
                            src={item.image}
                            alt="image"
                            className="rounded-full mx-auto border-[6px] border-white w-[90px] block shadow-[0_0_20px_-1px_rgba(231,231,231,1)]"
                          />
                          <i className="mdi mdi-format-quote-open relative -top-[30px] -right-[32px] h-5 w-5 leading-5 inline-block bg-custom text-white rounded-full"></i>
                        </div>
                        <div className="mt-6 text-center max-w-[400px] mx-auto">
                          <h5 className="text-lg font-bold leading-[1.4]">
                            {item.description}
                          </h5>
                          <p className="text-center mt-4 font-bold">
                            - {item.name}
                          </p>
                          <p className="text-custom mb-0">{item.projectName}</p>
                        </div>
                      </div>
                    </SwiperSlide>
                  ))}
                </div>

                <div className="swiper-pagination-center mx-auto text-center space-x-2"></div>
              </Swiper>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Coustomer;
