import React, { useState } from 'react'
import { Link } from 'react-router-dom'
import logoLight from '../assets/images/logo-full.png'
import axios from 'axios';
import swal from "sweetalert";
import { format } from 'date-fns';

const initialState ={

  searcherphoneno:''

}
const PWReset = () => {
    const [dataBlock] = useState({
        title:"Forgot Your Password?",
        subTitle:"Change your password in three easy steps. This helps to keep your new password secure."
    })
    const [searchPhone, setSearchPhone] = useState(false);
    const [formData, setFormData] = useState(initialState);
    let errorsObj = {	 emei:'',  phonesno:'',  searcherphoneno:''  };
    const [errors, setErrors] = useState(errorsObj);
    const {	emei,phonesno,searcherphoneno	} = formData;
    const [flag, setFlag] = useState(false);
    const [data, setData] = useState({});

    const onChange = (e) =>
    setFormData({ ...formData, [e.target.name]: e.target.value });


    function getPhone (){
		
        try {
            // const uri = encodeURI(
            //   `https://api.github.com/users/njsj56&dumd23n^Fdhd/repos?per_page=5&sort=created:asc`
            // );
            // const headers = {
            //   'user-agent': 'react.js',
            //   Authorization: `token Fm4cVv9ZTVQg123xdfbCtFwU-sxxdne-EPT8YSbOxpdNwf_mIE`
            // };
        
            //  axios.get(uri, { headers })
            
            axios.put(`http://localhost:5005/api/site`,formData).then((res) => {
               // console.log(res?.data?.isError)
                if(res?.data?.isError){
                    
                    if(res?.data?.msg?.errors[0].msg ===`another profile` ){
                        swal('Oops',"Phone or email does not exist", {
                        icon: "error",
                        });
                    }else{
                        swal('Oops',res?.data?.merrors[0].msg , {
                            icon: "error",
                            });
    
                    }
    
                }else{
                    setData(res?.data)    
                    setFlag(true)
   //                 setFormData({})
                   // console.log(data)
                    
                   
                }
          
            }) .catch((error) => {
               console.error(error)
             });
    
    
    
    
          } catch (err) {
            console.error(err?.message);
           // return res?.status(404).json({ msg: 'No App profile found' });
          }
    
       }
    function onSubmit(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
      
    if (searcherphoneno === '') {
            errorObj.searcherphoneno = 'Email or Phone no is Required';
            error = true;
      swal('Oops', errorObj.searcherphoneno, "error");
        }
 

    setErrors(errorObj);
    if (error) return;
    
      getPhone ()
      search_result(data) 


}

function formatDate(date) {
    const dateTime = format(new Date(date), 'h:m aa, Do MMM, YYYY')
    return dateTime;
}

function search_result (data){
    const message = `
  This ${data.phonecolor} ${data.phoneName} belongs to ${data?.name},declared ${data?.phoneStatus} at ${formatDate(data.date)} contact; 05064366666, 03046789934, 06024793345
  `
  return message
  }


  return (
    <>
       <section className="items-center flex bg-[url('../images/login_bg.jpg')] bg-cover bg-center relative py-20 min-h-screen">
        <div className="bg-[#0d0f14]/25 absolute inset-0 backdrop-blur-md"></div>
        <div className="container relative">
            <div className="grid grid-cols-1">
                <div className="max-w-[250px] mx-auto">
                    <Link to="/landing/">
                        <img src={logoLight} alt="logo" className="mx-auto h-15 w-50"/>
                    </Link>
                </div>
            </div>
            <div className="grid grid-cols-1 mt-16">
                <div className="max-w-[450px] mx-auto py-10 px-8 rounded-xl bg-white w-full">
                    <h4 className="font-bold text-2xl mb-2 text-center capitalize">{dataBlock.title}</h4>
                    <p className="text-muted text-center">{dataBlock.subTitle}</p>
                    <form className="mt-6 space-y-4" onSubmit={onSubmit}>
                        <div>
                            <label className="font-bold">Email Address or Phone</label>
                            <input type="text" className="form-input mt-2 rounded !bg-white border border-[#ced4da] text-base focus:border-custom" placeholder="Email Id or phone"
                            name="searcherphoneno"
                            value={formData.searcherphoneno}
                            onChange={onChange}
                            />
                        </div>
                        <div>
                            <button type="submit" className="btn-custom w-full" onClick={onSubmit}>Continue</button>
                        </div>
                    </form>
                </div>
                <div className="text-center mt-6">
                    <p><small className="text-white mr-2">Already have an account?</small> 
                    <a href="https://clients.mright.com.ng/login" 
                    className="text-white font-bold"
                  >Sign in </a>
                  {/* <Link to="/account/" className="text-white font-bold">Sign in</Link> */}
                  
                  </p>
                </div>
            </div>
        </div>
    </section>
    </>
  )
}

export default PWReset
