import React from "react";

const LogMainSection = () => {
    return (
        <>
            <section className="py-20">
                <div className="container">
                    <div className="grid grid-cols-1">
                        <div className="border-2 border-[#f6f6f6] py-[50px] px-10 space-y-8">
                            <div>
                                <h4 className="font-bold text-2xl">
                                    <span className="text-custom">1.0.0</span> -
                                    24th May 2023
                                </h4>
                                <h5 className="mt-6 text-xl font-bold">
                                    General
                                </h5>
                                <p className="text-muted mt-1">
                                    <span className="bg-custom h-2 w-2 mr-2 rounded-full inline-block"></span>{" "}
                                    Initial Released
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
};

export default LogMainSection;
