import React, { useState } from "react";
import overviewData from "../../assets/fake-data/overview";
import contact from "../../assets/fake-data/landingpage-three/contact";
import axios from 'axios';
import swal from "sweetalert";

const initialState ={
	email: '',
	message: '',
	name: '',
	subject: '',
}
const ContactSection = () => {

  const [formData, setFormData] = useState(initialState);
  const [flag, setFlag] = useState(false); 
  let errorsObj = {	 email:'',  message:'', name:'', subject:'' };
  const [errors, setErrors] = useState(errorsObj);
  const {	email,message,name, subject	} = formData;

  const isEmail = (email) =>
  /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(email);



  let apicall = ''
  if(process.env.REACT_APP_ENV === 'development') {
    apicall = process.env.REACT_APP_ENV_API_LC
  }else{
    apicall = process.env.REACT_APP_ENV_API
  }
  const onChange = (e) =>
  setFormData({ ...formData, [e.target.name]: e.target.value });

  function onSubmit(e) {
    e.preventDefault();
    let error = false;
    const errorObj = { ...errorsObj };
  
    if (!isEmail(email) || email === '' ) {
      errorObj.email = 'Valid Email is required';
      error = true;
      swal('Oops', errorObj.email, "error");
    }


    if (name === '') {
            errorObj.name = 'Fullname is Required';
            error = true;
      swal('Oops', errorObj.name, "error");
        }
    if (subject === '') {
            errorObj.subject = 'Subject of the message is Required';
            error = true;
      swal('Oops', errorObj.subject, "error");
        }

    if (message === '') {
            errorObj.message = 'Message is Required';
            error = true;
      swal('Oops', errorObj.message, "error");
        }



setErrors(errorObj);


//  phoneDetail.then(response=>setPhone(response))
if (error) return;
//  console.log(phone)
//phone &&  getLatLog(e)
setFlag(true)
sendEmail(e)
setFormData({email:'', message: '', name: '', subject: ''})

//  data && search_result(data) 

}

function sendEmail (e){
  e.preventDefault();
  try {

      
      axios.post(`${apicall}/site/contact_form`,formData).then((res) => {
         // console.log(res?.data?.isError)
          if(res?.data?.isError){
                
             
                  swal(res?.data?.msg?.errors[0].msg , {
                  icon: "error",
                  });
             
                  setFlag(false)
          }else{

           
            swal(`${res?.data?.msg?.errors[0].msg }!`, {
              icon: "success",
              });
              setFlag(false)
          }
    
      }) .catch((error) => {
         console.error(error)
    
       });




    } catch (err) {
      console.error(err?.message);
     // return res?.status(404).json({ msg: 'No App profile found' });
    }

 }
  return (
    <>
      <section className="py-20">
        <div className="container">
          <div className="grid grid-cols-1">
            <div className="max-w-[500px] w-full mx-auto">
              <form className="space-y-6">

                
                {/* {overviewData.map((x) => {
                  return (
                    <div key={x.id}>
                      <input
                        type={x.type}
                        className="form-input rounded-md !h-[55px] bg-white border !border-[#ced4da] focus:!border-custom"
                        placeholder={x.placeHolder}
                        required=""
                      />
                    </div>
                  );
                })} */}


                {/* <div>
                  <button type="button" className="btn-custom w-full">
                    Send Message
                  </button>
                </div> */}


<div>
                  <input
                    type="text"
                    className="form-input rounded-md !h-[55px] bg-white border !border-[#ced4da] focus:!border-custom"
                    placeholder="Your name..."
                    required="required"
                    name="name"
                    value={formData.name}
                    onChange={onChange}
                  />
                </div>
                <div>
                  <input
                    type="email"
                    className="form-input rounded-md !h-[55px] bg-white border !border-[#ced4da] focus:!border-custom"
                    placeholder="Your email..."
                    required="required"
                    name="email"
                    value={formData.email}
                    onChange={onChange}
                  />
                </div>

                <div>
                <input
                    type="text"
                    className="form-input rounded-md !h-[55px] bg-white border !border-[#ced4da] focus:!border-custom"
                    placeholder="Your Subject..."
                    required="required"
                    name="subject"
                    value={formData.subject}
                    onChange={onChange}
                  />
                </div>

                <div>
                  <textarea
                    rows="5"
                    className="form-input rounded-md bg-white border !border-[#ced4da] focus:!border-custom h-auto"
                    placeholder="Your message..."
                    required="required"
                    spellCheck="true"
                    name="message"
                    value={formData.message}
                    onChange={onChange}
                  ></textarea>
                </div>


                <div>
                  <button type="button"  onClick={onSubmit} className="btn-custom w-full" disabled={flag}>
                    Send Message
                  </button>
                </div>

              </form>
            </div>
          </div>
          <div className="bg-dark/[0.04] h-[1px] my-[70px] w-full inline-block"></div>
          <div className="grid grid-cols-1 md:grid-cols-3 gap-[30px]">
            {contact.map((x) => {
              return (
                <div key={x.id} className="text-center">
                  <div className="bg-custom/20 w-[50px] h-[50px] flex items-center mx-auto justify-center leading-[50px] rounded-lg">
                    <img
                      src={x.img}
                      alt={x.img}
                      className="mx-auto inline-block h-[30px]"
                    />
                  </div>
                  <h4 className="mt-4 font-bold text-xl">{x.name}</h4>
                  <p className="mt-2 text-muted">{x.text}</p>
                  <p className="mt-1 text-muted">{x.subText}</p>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default ContactSection;
