import React, { useState,useEffect } from "react";
import aboutImg from "../../assets/images/about_com.jpg";
import { Link } from "react-router-dom";

const ContentSection = ({designDataBlock,userDataBlock}) => {
   
  
  return (
    <>
      <section className="py-20 bg-white">
        <div className="container">
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-[30px]">
            <div className="max-w-[450px] mx-auto">
              <p className="[&>span]:bg-custom tracking-[6px] text-xs uppercase [&>span]:h-3 z-10 [&>span]:w-[60px] [&>span]:inline-block [&>span]:absolute [&>span]:opacity-10 relative ">
                <span></span>
                {designDataBlock?.titleSmall}
              </p>
              <h3 className="font-bold text-[40px] capitalize mt-6">
                {designDataBlock?.title}
              </h3>
              <p className="text-muted mt-6">{designDataBlock?.desc}</p>
              <div className="mt-10">
                <Link to="#" className="btn-custom rounded-full">
                  Explore
                </Link>
              </div>
            </div>
            <div>
              <img src={designDataBlock?.imageLarge.fields.file.url} alt="com" className="mx-auto rounded-[30px]" />
            </div>
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 items-center gap-[30px] mt-16">
            <div>
              <img src={userDataBlock?.imageLarge.fields.file.url} alt="com" className="mx-auto rounded-[30px]" />
            </div>
            <div className="max-w-[450px] mx-auto">
              <p className="[&>span]:bg-custom tracking-[6px] text-xs uppercase [&>span]:h-3 z-10 [&>span]:w-[60px] [&>span]:inline-block [&>span]:absolute [&>span]:opacity-10 relative ">
                <span></span>
                {userDataBlock?.titleSmall}
              </p>
              <h3 className="font-bold text-[40px] capitalize mt-6">
                {userDataBlock?.title}
              </h3>
              <p className="text-muted mt-6">{userDataBlock?.desc}</p>
              <div className="mt-10">
                <Link to="#" className="btn-custom rounded-full">
                  Explore
                </Link>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default ContentSection;
