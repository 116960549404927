import React, {  useEffect,Fragment, useState, useRef }  from "react";
import axios from 'axios';
import swal from "sweetalert";
import { format } from 'date-fns';
import useGeoLocation from "./useGeoLocation";
import Numlookupapi from '@everapi/numlookupapi-js';
import ReactPlayer from 'react-player';
import Privacy from "./Privacy";
 import mright from "../assets/images/mright-spinner.mp4";


const initialState ={
	emei: '',
	phonesno: '',
  searcherphoneno:''
 }

//g6nF4DWyqADF_Tj

export default function Modal() {
  const [showModal, setShowModal] = useState(false);
  const [colorStatus, setColorStatus] = useState('');
  const [showPrivacy, setShowPrivacy] = useState(true);
  const [searchPhone, setSearchPhone] = useState(false);
  const [formData, setFormData] = useState(initialState);
  const [data, setData] = useState({});
  const [flag, setFlag] = useState(false);
  const [otp, setOtp] = useState(false);
  const [service, setService] = useState(false);
  let errorsObj = {	 emei:'',  phonesno:'',  searcherphoneno:''  };
  const [errors, setErrors] = useState(errorsObj);
  const {	emei,phonesno,searcherphoneno	} = formData;
  const [enabled, setEnabled] = useState(false);
  const [display, setDisplay] = useState(false);
  const [spinner, setSpinner] = useState(false);
  
  const [phone, setPhone] = useState({});

  const [location, setLocation] = useState({
    loaded: false,
    coordinates: { lat: "", lng: "" },
});

const [error, setError] = useState({
  loaded: false,
  error: { code: "", message: "" },
});

const onSuccess = (location) => {
    setLocation({
        loaded: true,
        coordinates: {
            lat: location.coords.latitude,
            lng: location.coords.longitude,
        },
    });
    setEnabled(true);
};

const onError = (error) => {
  setError({
      loaded: true,
      error: {
          code: error.code,
          message: error.message,
      },
  });
};
let detail = {}
let color = ''
const options = {
  enableHighAccuracy: true,
  timeout: 10000,
};

const getLatLog = (e) => {
  e.preventDefault();
  navigator.geolocation.getCurrentPosition(onSuccess, onError,options);
};

const enableFn = (e) => {
  e.preventDefault();
   getLatLog(e)
  //navigator.geolocation.getCurrentPosition(onSuccess, onError,options);
};

const verifyPhoneNum_manual = async (e) => {

 //   /\d/g

// const reges = formData?.searcherphoneno.match(/^0\d{11}$/).length===11
  e.preventDefault();
  if(!formData?.searcherphoneno.match(/^0\d/g)){
    swal("Invalid Phone! Use local format e.g 08023477876");
    
  }else{
    //   const numlookupapi = new Numlookupapi('num_live_5YKSb886cVqWfFznmgWjPTBhD53kvbVDkK9dgRxv');
    //   const rtn =  await numlookupapi.validate(formData.searcherphoneno, ({
    //     country_code: 'NG'
    // }));

    const rtn = {
      valid: true,
       number: formData.searcherphoneno,
        location: null
    }

    setPhone(rtn)
    if (!rtn.valid)  {
      swal("Invalid Phone! Use local format e.g 08023477876");
    
    }else{
    
      // confirmPhone (e)
     getPhone (e,rtn)
     const timer = ()=> setTimeout(() => {
      setFlag(true)
         setSpinner(false);
     }, 15000);
     timer()

    // data && search_result(data) 

    
    }
  }



// let rtn =
// {
//    "valid": true,
//    "number": "08023788111",
//    "local_format": "4158586273",
//    "international_format": "+14158586273",
//    "country_prefix": "+1",
//    "country_code": "US",
//    "country_name": "United States of America",
//    "location": "Novato",
//    "carrier": "AT&T Mobility LLC",
//    "line_type": "mobile"
//  }


//setFormData({ ...formData, valid2:rtn.valid });

//console.log(rtn)
// {
//   "valid": true,
//   "number": "14158586273",
//   "local_format": "4158586273",
//   "international_format": "+14158586273",
//   "country_prefix": "+1",
//   "country_code": "US",
//   "country_name": "United States of America",
//   "location": "Novato",
//   "carrier": "AT&T Mobility LLC",
//   "line_type": "mobile"
// }


//getPhone (e)


//return {...rtn}
};
const verifyPhoneNum = async (e) => {


  e.preventDefault();
  if(!formData.searcherphoneno){
    swal("Invalid Phone! Use local format e.g 08023477876");
  }else{
      const numlookupapi = new Numlookupapi('num_live_5YKSb886cVqWfFznmgWjPTBhD53kvbVDkK9dgRxv');
      const rtn =  await numlookupapi.validate(formData.searcherphoneno, ({
        country_code: 'NG'
    }));
    setPhone(rtn)
    if (!rtn.valid)  {
      swal("Invalid Phone! Use local format e.g 08023477876");
    
    }else{
    
      // confirmPhone (e)
     getPhone (e,rtn)
     const timer = ()=> setTimeout(() => {
      setFlag(true)
         setSpinner(false);
     }, 15000);
     timer()

    // data && search_result(data) 

    
    }
  }



// let rtn =
// {
//    "valid": true,
//    "number": "08023788111",
//    "local_format": "4158586273",
//    "international_format": "+14158586273",
//    "country_prefix": "+1",
//    "country_code": "US",
//    "country_name": "United States of America",
//    "location": "Novato",
//    "carrier": "AT&T Mobility LLC",
//    "line_type": "mobile"
//  }


//setFormData({ ...formData, valid2:rtn.valid });

//console.log(rtn)
// {
//   "valid": true,
//   "number": "14158586273",
//   "local_format": "4158586273",
//   "international_format": "+14158586273",
//   "country_prefix": "+1",
//   "country_code": "US",
//   "country_name": "United States of America",
//   "location": "Novato",
//   "carrier": "AT&T Mobility LLC",
//   "line_type": "mobile"
// }


//getPhone (e)


//return {...rtn}
};



useEffect(() => {
//  if(display){
//     if (!("geolocation" in navigator)) {
//         // onError({
//           setError({
//             loaded: true,
//             error: {
//                 code: error.code,
//                 message: error.messge,
//             },
//         });

//     }
//  }  


 // phone &&  getLatLog()
  //location.loaded && getPhone()
  //navigator.geolocation.getCurrentPosition(onSuccess, onError);

  
  //console.log(phone.valid)

}, [display,phone]);

  let apicall = ''
  if(process.env.REACT_APP_ENV === 'development') {
    apicall = process.env.REACT_APP_ENV_API_LC
  }else{
    apicall = process.env.REACT_APP_ENV_API
  }

       useEffect(() => {	
        
	     }, []);

  
  // function checkReferral (){
		
  //   if(searchPhone) {
  //       //alert('yes')
  //       axios.get(`profile/site/phone`,formData).then((res) => {
  //           console.log(res.data)

      
  //       }) .catch((error) => {
  //           console.error(error)
  //        });
        
    
    
  //   }

  //  }
  function confirmPhone (e){
    e.preventDefault();
    try {

        
        axios.put(`${apicall}/site/confirm_number`,formData).then((res) => {
           // console.log(res?.data?.isError)
            if(res?.data?.isError){
                  
                if(res?.data?.msg?.errors[0].msg ===`another profile` ){
                    swal('Oops',"This phone is unknown, unconfirm or Not added to M-right system.There is danger in buying this phone, especially if the serial number is typed correctly and still showing this message. It might be stolen or crime implicated phone.There is risk in buying it unless you trust the seller and has the receipt and the park of the phone.", {
                    icon: "error",
                    });
                    const timer = ()=> setTimeout(() => {
                          setSpinner(false);
                     }, 15000);
                    timer()
                }else{
                // verifyPhoneNum(e)
                 verifyPhoneNum_manual(e)
                }
                //else{
                //     swal('Oops',res?.data?.msg?.errors[0].msg , {
                //         icon: "error",
                //         });

                // }

            }else{

              getPhone (e)
              const timer = ()=> setTimeout(() => {
                   setFlag(true)
                   setSpinner(false);
               }, 15000);
              timer()
              // clearTimeout(timer);
          //  data && search_result(data) 

              
             
            
                // setData(res?.data)    
              //  setOtp(true)
                // setFormData({})
               // console.log(data)
               //swal(`Your search result is ready!, enter the OTP Code sent to your phone [${res?.data?.msg?.errors[0].OTP }]`, { icon: "success" });
              // swal(`Your search result is ready!`, { icon: "success" });
               //Missing or Stolen
            }
      
        }) .catch((error) => {
           console.error(error)
         });




      } catch (err) {
        console.error(err?.message);
       // return res?.status(404).json({ msg: 'No App profile found' });
      }

   }
   


  function onVerify (e){

    e.preventDefault();

    try {

        
        axios.put(`${apicall}/site/verify`,formData).then((res) => {
           // console.log(res?.data?.isError)
            if(res?.data?.isError){
                
                if(res?.data?.msg?.errors[0].msg ===`another profile` ){
                    swal('Oops',"Serial number does not exist in the system", {
                    icon: "error",
                    });
                }else{
                    swal('Oops',res?.data?.msg?.errors[0].msg , {
                        icon: "error",
                        });

                }

            }else{
                // setData(res?.data)    
                setService(true)
                setDisplay(true)
                // setFormData({})
               // console.log(data)
              // swal(`Your search result is ready!, enter the OTP Code sent to your phone [${res?.data?.msg?.errors[0].OTP }]`, { icon: "success" });
               
            }
      
        }) .catch((error) => {
           console.error(error)
         });




      } catch (err) {
        console.error(err?.message);
       // return res?.status(404).json({ msg: 'No App profile found' });
      }

   }

  function getPhone (e, rtn){
    e.preventDefault();
    try {
        let detail = ''
       
        if(rtn) detail = {...formData, valid: rtn.valid, number: rtn.number, location: rtn.location }
        else detail = {...formData, valid: false}
       
        axios.put(`${apicall}/site`, detail ).then((res) => {
           // console.log(res?.data?.isError)
            if(res?.data?.isError){
                
                if(res?.data?.msg?.errors[0].msg ===`another profile` ){
                    swal('Oops',"This phone is unknown, unconfirm or Not added to M-right system.There is danger in buying this phone, especially if the serial number is typed correctly and still showing this message. It might be stolen or crime implicated phone.There is risk in buying it unless you trust the seller and has the receipt and the park of the phone.", {
                    icon: "error",
                    });
                }else{
                    swal('Oops',res?.data?.msg?.errors[0].msg , {
                        icon: "error",
                        });
                } 
                setSpinner(false);
               

            }else{

             setData(res?.data)    
 
                //setFlag(true)
                setFormData({})
                console.log()
                if ( res?.data?.phone_detail[0].phoneStatus==='IN USE'){
                  setColorStatus('green')
              }else if( res?.data?.phone_detail[0].phoneStatus==='Missing or Stolen' )
              setColorStatus('red')
              else if( res?.data?.phone_detail[0].phoneStatus==='Forsale' )
              setColorStatus('orange')
               
            }
      
        }) .catch((error) => {
           console.error(error)
         });

      } catch (err) {
        console.error(err?.message);
       // return res?.status(404).json({ msg: 'No App profile found' });
      }

   }


   const onChange = (e) =>
   setFormData({ ...formData, [e.target.name]: e.target.value });

   const onReset = (e) =>{
    e.preventDefault();
   // alert('alert me')
    setFormData({
      emei: '',
      phonesno: '',
      searcherphoneno:''
    
    })
    setFlag(false)
   
  };

   function onSubmit(e) {
        e.preventDefault();
        let error = false;
        const errorObj = { ...errorsObj };
      
    if (phonesno === '') {
            errorObj.phonesno = 'Phone serial no is Required';
            error = true;
      swal('Oops', errorObj.phonesno, "error");
        }
 
    if (searcherphoneno === '') {
            errorObj.searcherphoneno = 'Valid Phone number is Required';
            error = true;
      swal('Oops', errorObj.searcherphoneno, "error");
        }
    if (searcherphoneno.length < 11 || searcherphoneno.length > 11 ) {
            errorObj.searcherphoneno = 'Valid Phone number is requied';
            error = true;
      swal('Oops', errorObj.searcherphoneno, "error");
        }
        let dd = formData?.searcherphoneno.match(/^0\d/g)
        //alert(dd)
        if(!dd){
            errorObj.searcherphoneno = 'Invalid Phone! Use local format e.g 08023477876';
            error = true;
      swal('Oops', errorObj.searcherphoneno, "error");
        }
   
    setErrors(errorObj);
  
    //  phoneDetail.then(response=>setPhone(response))
    if (error) return;
  //  console.log(phone)
  //phone &&  getLatLog(e)
  setSpinner(true);
  confirmPhone(e)
    //  data && search_result(data) 
}
 
      function formatDate(date) {
          const dateTime = format(new Date(date), 'do MMM, yyyy')
          return dateTime;
      }
      // function formatDate(date) {
      //     const dateTime = format(new Date(date), 'h:mm aa, Do MMM, YYYY')
      //     return dateTime;
      // }
      function formatTime(date) {
          const dateTime = format(new Date(date), 'h:mm aa')
          return dateTime;
      }

      function search_result (data){
        const message = `
      This ${data.phonecolor} ${data.phoneName} belongs to ${data?.name},declared ${data?.phoneStatus} on ${formatDate(data.date)}, at ${formatTime(data.date)} contact M-right @ 07019490507
      `
      return message
      }

      function PlayerComponent() {
        const playerRef = useRef(null);
        return (
           <div>
              <ReactPlayer ref={playerRef} url={mright} controls={false} playing={true} loop={true} />
           </div>
        )
     };

  return (
    <> 
    
  
     <div className="grid grid-cols-1 mt-24">
        <div className="text-center">
         
      <button
      className=" font-normal text-sm mr-10 rounded-full capitalize top-1 right-1 outline-none py-[11px] px-[30px] hover:bg-[#07897e] hover:border-[#07897e] duration-500 bg-custom text-white border border-custom"
        type="button"
        onClick={() => setShowModal(true)}
      >

         SEARCH PHONE STATUS 

      </button>

      </div>
        </div>

      {showModal ? (
        <>
      
             <div
            className="justify-center  items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none"
          >
            <div className="relative w-auto my-6 mx-auto max-w-3xl">
              {/*content*/}
              <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                {/*header*/}
                <div className="flex items-start justify-between p-5 border-b border-solid border-slate-200 rounded-t ">
                  <h3 className="text-3xl font-semibold">
                    Search Phone Status
                  </h3>
                  <button
                    className=" ml-auto bg-black border-0 text-black float-right text-3xl leading-none font-semibold outline-none focus:outline-none"
                    onClick={() => {setShowModal(false);setFlag(false)}}
                  >
                    <span className="bg-white text-black pb-2 h-6 w-6 text-2xl block outline-none focus:outline-none">
                      ×
                    </span>
                  </button>
                </div>
                {/*body*/}
                <div className="relative p-6 flex-auto justify-item-center">
                  {/* <p className="my-4 text-slate-500 text-lg leading-relaxed">
                      { !location.loaded && (<Fragment>
                        <p className="text-red-900 text-bold">NOTE**</p>
                            <div className=""> this site uses location services, click the button below to enable location services</div>
                            <div className="relative flex flex-col items-center justify-center overflow-hidden">   
                            <div className={ (location.loaded ? "text-green-500" : "text-red-500")}> { (location.loaded
                                    ? ``
                                    : (error.error.message==='User denied Geolocation') && `Your phone or device location service is off. Enable it in the top left, click on location icon, just before this web page name`)}
                                    <br />
                                    </div>
                        <div className="flex">
                          
                            <label class="inline-flex relative items-center mr-5 cursor-pointer">
                                <input
                                    type="checkbox"
                                    className="sr-only peer"
                                    checked={enabled}
                                    readOnly
                                />
                                <div
                                    onClick={(e) => {
                                        //setEnabled(!enabled);
                                        enableFn(e)
                                    }}
                                    className="w-11 h-6 bg-gray-200 rounded-full peer  peer-focus:ring-green-300  peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-0.5 after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-green-600"
                                ></div>
                                <span className="ml-2 text-sm font-medium text-gray-900">
                                    {enabled?'ON':'OFF'}
                                </span>
                               
                           
                            </label>
                        </div>
                        </div>

                      </Fragment>)}
                       { location.loaded && JSON.stringify(location)}
                    </p> */}
                  <form className="relative max-w-[420px] mx-auto mt-6" onSubmit={onSubmit}>
                       
                       { !otp && (
                        <span> <input type="text" 
                               className="form-input h-[52px] py-3.5 px-4 text-sm !pr-[100px] text-dark rounded-md bg-white border border-solid !border-[#000000] mb-2"  
                               placeholder="Type Phone Serial Number"
                               name="phonesno"
                               value={formData.phonesno}
                               onChange={onChange}
                        
                        />

                                          <input type="number" 
                                                      className="form-input h-[52px] py-3.5 px-4 text-sm !pr-[160px] text-dark rounded-md bg-white border border-solid !border-[#ededed] mb-2"  
                                                      placeholder="Your phone number e.g 08023766333"
                                                      name="searcherphoneno"
                                                      value={formData.searcherphoneno}
                                                      onChange={onChange}
                                                     
                                                />
                                                <div className="justify-content-center">
                                                 <button 
                                                    className={` font-bold text-sm mr-10 rounded-full capitalize top-1 right-1 outline-none py-[11px] px-[30px] ${enabled?'hover:bg-[#07897e] hover:border-[#07897e] duration-500 bg-green':''} text-black border border-custom`}
                                                    onClick={onSubmit}
                                                    disabled={(!formData.phonesno && !enabled)}
                                                    >
                                                      Search
                                                    </button>
                                                    <button  onClick={ (e)=>onReset(e) }    className=" font-bold text-sm mr-10 rounded-full capitalize top-1 right-1 outline-none py-[11px] px-[30px] hover:bg-[#07897e] hover:border-[#07897e] duration-500 bg-green text-black border border-custom">
                                                        RESET
                                                      </button>
                                                </div>
                                              
                        </span>)}

                       {/* { 
                       
                       (otp && !service) && (<span>
                              <input type="text" 
                                    className="form-input h-[32px] py-3.5 px-4 text-sm !pr-[160px] text-dark rounded-md bg-white border border-solid !border-[#ededed] mb-2"  
                                    placeholder="Enter OTP"
                                    name="otp"
                                    value={formData.otp}
                                    onChange={onChange}
                              
                              /> <button 
                              className="relative tracking-[1px] font-normal text-sm rounded-md capitalize top-1 right-1 outline-none py-[11px] px-[30px] hover:bg-[#07897e] hover:border-[#07897e] duration-500 bg-custom text-white border border-custom"
                              onClick={(e)=> onVerify(e)}
                              >
                                Verify OTP
                              </button>
                        </span>)
                  } */}

                        
                    </form>
                 
               { service && 
                ( <div>
                  {/* <div className="row justify-content-center mt-3">
                            <div className="col-lg-6 text-center text-dark">
                              DEVICE LOCATION SERVICE STATUS:
                              
                              <div className={ (location.loaded ? "text-green-500" : "text-red-500")}> { (location.loaded
                                    ? `ACTIVE`
                                    : error.error.message)}
                                    <br />
                                    </div>
                             { location.loaded && JSON.stringify(location)}
                   
                           
                            </div>
                        </div> */}
                        {/* <div className="text-black-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                        >{ error.loaded && 'This SITE uses Location Service Activate Your Device Location Service and click on Get Search Result below'}</div> */}

                       {/* {error.loaded && ( <button
                                className="form-input h-[52px] py-3.5  text-sm !pr-[100px] relative tracking-[1px] font-normal text-sm rounded-md capitalize top-1 right-1 outline-none py-[11px] px-[30px] hover:bg-[#07897e] hover:border-[#07897e] duration-500 bg-custom text-white border border-custom"
                             type="button"
                              onClick={() => {setDisplay(true)}}
                              disabled={true}
                        >
                          Get Search Result 
                        </button>)} */}

                        
                        </div>)} 
                { spinner && 

                <div className={`text-normal-500 px-6 py-5 mt-5 rounded shadow hover:shadow-lg bg-[#ededed] font-bold`}>
                   <PlayerComponent/>
                </div>
                }
                { flag && (<div  className={`text-normal-500 px-6 py-5 mt-5 rounded shadow hover:shadow-lg bg-[#ededed] font-bold`}>
                  
          {`This ${data?.phone_detail[0].phonecolor} ${data?.phone_detail[0].phoneName} belongs to ${data?.yourname} ${data?.father}, declared `} 
          <span className={`text-${colorStatus}-500`}>{` ${data?.phone_detail[0].phoneStatus}`}</span>
{` on ${formatDate(data?.phone_detail[0].date)} at ${formatTime(data?.phone_detail[0].date)} contact M-right @ mrightproject@mright.com.ng or 07019490507 (sms & Whatsapp only)`}
         </div>)}
                     
         <div>
        
      </div>

                </div>
                {/*footer*/}
                <div className="flex items-center justify-end p-6 border-t border-solid border-slate-200 rounded-b">
                  <button
                    className="text-red-500 background-transparent font-bold uppercase px-6 py-2 text-sm outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => {setShowModal(false);setFlag(false);setSpinner(false);}}
                  >
                    Close
                  </button>
                
                  {/* <button
                    className="bg-emerald-500 text-red active:bg-emerald-600 font-bold uppercase text-sm px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 ease-linear transition-all duration-150"
                    type="button"
                    onClick={() => setShowModal(false)}
                  >
                    Save Changes
                  </button> */}
                </div>
              </div>
            </div>
          </div> 
          {/* <Privacy showModal={showPrivacy}  setShowModal= {setShowPrivacy}/> */}
          <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
 
        </>
      ) : null}
    </>
  );
}