const pricingplan = [
    {
        id:0,
        plan:"Starter",
        savePercent:"20%",
        price:"$29",
        subText: "/Per Month",        
    },
    {
        id:1,
        plan:"Enterprice",
        savePercent:"25%",
        price:"$59",
        subText: "/Per Month",        
    },
    {
        id:2,
        plan:"Starter",
        savePercent:"30%",
        price:"$89",
        subText: "/Per Month",        
    },
]

const planTable = [
    {
        id: 0,
        text1: "2GB Cloud Storage",
        text2: "100GB CDN Bandwidth",
        text3: "98.88% Uptime Guarantee",
        text4: "Email Power Tools",
    },
    {
        id: 1,
        text1: "20GB Cloud Storage",
        text2: "1TB CDN Bandwidth",
        text3: "99.95% Uptime Guarantee",
        text4: "Personal Account Manager",
    },
    {
        id: 2,
        text1: "100TB CDN Bandwidth",
        text2: "API/Formulas Support*",
        text3: "99.99% Uptime Guarantee",
        text4: "Enterprise SLA",
    },
]

export {pricingplan, planTable} 