import React from "react";
import services from "../../assets/fake-data/servicies/servicies";

const ServiciesSection = ({content1}) => {
  return (
    <>
      <section className="py-20 bg-white">
        <div className="container">
          <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-1 gap-[30px]">
            {services.map((x) => {
                let xx = '';
                let xx2 = '';
                if(x.id === 1) {
                  xx= content1?.columnTitle1
                  xx2= content1?.columnDesc1

                }
                if(x.id === 2) {
                  xx= content1?.columnTitle2
                  xx2= content1?.columnDesc2
                }
                if(x.id === 3) {
                  xx= content1?.columnTitle3
                  xx2= content1?.columnDesc3
                }
              return (
                <div key={x.id} className="text-center p-4">
                  <div>
                    <i
                      className={`${x.icon} text-white/80 text-[40px] leading-[84px] text-center w-[84px] h-[84px] inline-block bg-custom rounded-[10px]`}
                    ></i>
                  </div>
                  <div className="mt-6">
                    <h4 className="font-bold text-2xl">{xx}</h4>
                    <p className="text-muted mt-4">{xx2}</p>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </>
  );
};

export default ServiciesSection;
