import React, { useEffect, useState } from 'react'
import { createClient } from "contentful";
import FooterThree from "../components/footers/FooterThree";
import ContentSection from "../features/common/ContentSection";
import FAQ from "../features/common/FAQ";
import NeedHelp from "../features/common/NeedHelp";
import ServiciesSection from "../features/servicies/ServiciesSection";
import MainHeroSection from "../features/common/MainHeroSection";

const Servicies = () => {
  const [home, setHome] = useState([]);
  const [blogPosts, setBlogPosts] = useState([])

  const client = createClient({
  //  space: process.env.REACT_APP_SPACE_ID,
    space: "vcapyi6fg0e5",
  // accessToken: process.env.REACT_APP_ACCESS_TOKEN,
    accessToken: "xpa9NUxBNUIsWaiJNltFGuirPfhhXvXIAJJpNZcgnUg",
    host: "preview.contentful.com"
  });



  useEffect(() => {

    const getService = async () => {
      try {
        const entries = await client.getEntries({
          content_type: "services",
          select: "fields"
        });
    
        const sanitizedEntries = entries.items.map((item) => {
         // const avatar = item.fields.avatar?.fields;
          return {
            ...item.fields
          };
        });
        setBlogPosts(sanitizedEntries)  ;
      } catch (error) {
        console.log(`Error fetching home ${error}`);
      }
    };

    const getHome = async () => {
      try {
        const entries = await client.getEntries({
          content_type: "home",
          select: "fields"
        });
    
        const sanitizedEntries = entries.items.map((item) => {
         // const avatar = item.fields.avatar?.fields;
          return {
            ...item.fields
          };
        });
    
        setHome(sanitizedEntries);
      } catch (error) {
        console.log(`Error fetching home ${error}`);
      }
    };
    
    getHome()
    getService()

 
   },[]);

   const content1 = blogPosts.find((element) => {
    return element.section==='Section 1';
  })
  const content2 = home.find((element) => {
    return element.section==='Section 2';
  })
  
  
  
  const content3 = home.find((element) => {
    return element.section==='Section 3';
  })


  return (
    <>
      <MainHeroSection
        title={content1?.title}
        subTitle={content1?.desc}
      />
      <ServiciesSection content1={content1} />
      <ContentSection  designDataBlock={content2} userDataBlock={content3} />
      {/* <FAQ />
      <NeedHelp /> */}
      <FooterThree />
    </>
  );
};

export default Servicies;
